@charset "utf-8";
@import "lib/reset.min.css";
@import "lib/slick.css";
@import "lib/slick-theme.css";
@import "_vars.sass";

@media only screen and (max-width: $breakpoint1 - 1) {

  .is-pc {
    display: none !important; }
  .is-tb {
    display: none !important; }

  html {
    overflow-y: scroll; }

  body {
    color: $black;
    background: $white;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "palt";
    font-family: "Poppins",   "dnp-shuei-gothic-kin-std", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Arial, sans-serif;
    letter-spacing: 0.05em;
    line-height: 1.5;
    overflow-x: hidden;
    font-size: 14px;

    &.index {
      color: $white;
      background: $text-black;
      header {
        color: $white;
        background: $black; }
      #menu {
        color: $white;
        background: $black; } }
    &.en {

      footer {
        .inner {
          .schedule {
            ul {
              li {
                span {
                  &.head {
                    width: 170px; } } } } } } }

      section.title {
        .inner {
          .head {
            span {
              &.headjp {
                letter-spacing: 0em; } } } } }

      section.kv {
        .inner {
          .left {
            .showing {
              a {
                letter-spacing: 0em; } } } } }

      section.upcoming {
        .inner {
          .right {
            .info {
              a {
                letter-spacing: 0em; } } } } }

      section.access {
        .inner {
          .schedule {
            ul {
              li {
                span {
                  &.head {
                    width: 170px; } } } } } } }

      section.access {
        background: #555560;
        color: $white; }

      section.about {
        .inner {}
        .statement {
          .head {
            font-weight: 800;
            font-size: 40px;
            line-height: 1.0; } }
        .about-blocks {
          .block {
            .head {
              font-weight: 800;
              margin-bottom: 40px;
              line-height: 1.0; } } } }

      section.archive {
        .inner {
          .head {
            span {
              &.headjp {
                letter-spacing: 0em; } } } }
        a.btn_more {
          letter-spacing: 0em; } } } }

  a {
    color: inherit;
    text-decoration: none;
    transition: opacity 0.3s $easeOutQuart;
    &:hover {
      opacity: .6;
      text-decoration: none; } }

  .h1 {
    font-weight: 600;
    font-size: 28px; }

  .h1e {
    font-size: 28px;
    font-weight: 700; }

  .h2 {
    font-weight: 700;
    font-size: 24px; }

  .h3 {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.3em; }

  .h3e {
    font-weight: 600;
    font-size: 20px; }

  .h4 {
    font-weight: 500;
    font-size: 20px; }

  .h4b {
    font-size: 20px;
    font-weight: 600; }

  .h4bb {
    font-size: 20px;
    font-weight: 700; }

  .ui {
    font-weight: 700;
    font-size: 16px; }

  .h5 {
    font-weight: 600;
    font-size: 16px; }

  .h5l {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.3em; }

  .h5m {
    font-weight: 500;
    font-size: 16px; }

  .sub {
    font-weight: 600;
    font-size: 12px; }

  .sube {
    font-size: 12px;
    font-weight: 700; }

  header {
    width: 100%;
    height: 88px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1200;
    background: $white;
    .inner {
      line-height: 48px;
      position: relative;
      height: 48px;
      .btn_menu {
        position: absolute;
        top: 0px;
        left: -12px; }
      .btn_close {
        position: absolute;
        top: 0px;
        left: -12px;
        display: none; }
      .twtter {
        position: absolute;
        top: 0px;
        right: 90px; }

      .lang {
        position: absolute;
        top: 0px;
        right: 0px;
        a.current {
          opacity: 0.5; } } } }

  #menu {
    width: 100%;
    position: fixed;
    top: 88px;
    left: 0px;
    background: $red;
    z-index: 1100;
    padding-bottom: 0px;
    transition: transform 0.3s $easeOutQuart;
    transform: translate(0px , -100%);
    background: $white;
    color: $black;
    &.show {
      transform: translate(0px , 0%);
      pointer-events: auto; }
    .inner {
      position: relative;
      padding-left: 20px;
      padding-right: 20px;
      nav {
        // padding-left: 220px
        ul {
          margin-bottom: 40px;
          &:last-child {
            margin-right: 0px; }
          li {
            font-size: 20px;
            margin-bottom: 4px;
            line-height: 36px;
            a {
              line-height: 36px;
              display: block; }
            i {
              width: 36px;
              height: 36px; }
            &:last-child {
              margin-bottom: 0px; } } } } } }

  .container {
    padding-top: 88px;

    section.title {
      padding-bottom: 40px;
      .logo {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        text-align: center;
        img {
          width: 100%;
          max-width: 280px; } }
      .inner {
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        .date {
          margin-top: 40px;
          padding-bottom: 100px; }
        h1 {
          position: absolute;
          top: 0px;
          left: 440px; }
        .head {
          span {
            display: block;
            line-height: 1.0;
            &.headjp {
              margin-top: 14px; } } } } }

    section.kv {
      box-sizing: border-box;
      .inner {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 80px;
        .left {
          .showing {
            padding-top: 40px;
            .head {
              span {
                display: block;
                line-height: 1.0;
                &.headjp {
                  margin-top: 14px; } } }

            .ex-title {
              margin-top: 60px;
 }              // font-size: 16px

            .date {
              font-size: 20px;
              font-weight: 600;
              margin-top: 20px; }

            .desc {
              margin-top: 40px;
              margin-bottom: 60px; }

            a {
              display: block;
              color: $white;
              background: $red;
              letter-spacing: 0.3em;
              line-height: 36px;
              padding-top: 31px;
              padding-bottom: 31px;
              text-align: center;
              i {
                width: 36px;
                height: 36px;
                transition: transform 0.3s $easeOutQuart; }
              &:hover {
                opacity: 1.0;
                i {
                  transform: translate(10px, 0px); } } } } }
        .right {
          img {
            width: 100vw;
            margin-left: -20px; } } } }

    section.upcoming {
      background: $blue;
      color: $white;
      .inner {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px;
        .left {
          position: relative;
          box-sizing: border-box;
          img {
            width: 100%;
            margin-top: -40px;
            border: 5px solid $blue;
            box-sizing: border-box; } }

        .right {
          position: relative;
          .head {
            margin-top: 40px;
            span {
              display: block;
              line-height: 1.0;
              &.headjp {
                margin-top: 14px; } } }
          .info {
            position: relative;
            .ex-title {
              margin-top: 40px;
              // width: 400px
 }              // font-size: 16px
            .date {
              font-size: 20px;
              font-weight: 600;
              margin-top: 30px;
              margin-bottom: 40px; }

            a {
              display: block;
              background: $red;
              letter-spacing: 0.3em;
              line-height: 36px;
              padding-top: 31px;
              padding-bottom: 31px;
              text-align: center;
              i {
                width: 36px;
                height: 36px;
                transition: transform 0.3s $easeOutQuart; }
              &:hover {
                opacity: 1.0;
                i {
                  transform: translate(10px, 0px); } } } } } } }

    section.pr {
      background: $stone;
      color: $black;
      .inner {
        // padding-left: 20px
        // padding-right: 20px
        position: relative;
        padding-top: 40px;
        // padding-bottom: 40px
        text-align: center;
        ul {
          font-size: 0px;
          letter-spacing: -100em;
          display: inline-block;
          li {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 40px);
            max-width: 335px;
            margin-bottom: 40px;
            margin-left: 20px;
            margin-right: 20px;
            text-align: left;
            transition: opacity 0.3s $easeOutQuart;
            &.hover {
              opacity: 0.7; }
            figure {
              position: relative;
              img {
                width: 100%; }
              figcaption {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 100px;
                height: 33px;
                line-height: 33px;
                text-align: center;
                background: $yellow; } }
            .title {
              display: block;
              width: 100%;
              background: $gray;
              box-sizing: border-box;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 20px;
              padding-bottom: 20px;
              color: $white;
              i {
                width: 36px;
                height: 36px; } }
            a {
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%; } } } } }

    section.topics {
      position: relative;
      background: $yellow;
      color: $black;
      .inner {
        padding-left: 20px;
        padding-top: 40px;
        padding-bottom: 0px;
        .head {
          // margin-top: 30px
          // margin-left: 40px
          span {
            display: block;
            line-height: 1.0;
            &.headjp {
              margin-top: 14px; } } }

        .topcis-list {
          margin-top: 40px;
          li {
            border-top: 2px solid $black;
            position: relative;
            list-style-type: none;
            span {
              display: block;
              padding-right: 60px; }
            a {
              display: block;
              padding-top: 26px;
              padding-bottom: 26px;
              transition: background-position 0.3s $easeOutQuart;
              background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 20px) center;
              background-size: 36px 36px;
              &:hover {
                opacity: 1.0;
                background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 10px) center;
                background-size: 36px 36px; } } } }
        a.btn_more {
          color: $white;
          display: block;
          background: $red;
          letter-spacing: 0.3em;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          margin-right: 20px;
          i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s $easeOutQuart; }
          &:hover {
            opacity: 1.0;
            i {
              transform: translate(10px, 0px); } } } } }

    section.access {
      background: $blue;
      color: $white;
      .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 80px;
        position: relative;
        h3.head {
          span {
            display: block;
            line-height: 1.0;
            &.headjp {
              margin-top: 14px; } } }

        .address {
          margin-top: 40px;
          margin-bottom: 40px; }

        .btn_map {
          color: $white;
          display: block;
          background: $red;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          margin-left: -20px;
          i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s $easeOutQuart; }
          &:hover {
            opacity: 1.0;
            i {
              transform: translate(0px, -5px); } } }

        .schedule {
          // width: 620px
          margin-top: 40px;
          ul {
            li {
              list-style-type: none;
              margin-bottom: 20px;
              span {
                display: inline-block;
                vertical-align: top;
                &.head {
                  width: 90px; } } } }
          p {
            margin-bottom: 20px;
            span {
              display: inline-block;
              vertical-align: top;
              &.head {
                width: 90px; } } } }

        .pub-transport {
          margin-top: 40px;
          margin-left: -20px;
          padding-left: 20px;
          background: $stone;
          color: $black;
          .transport {
            position: relative;
            padding-top: 40px;
            padding-bottom: 40px;
            &:first-child {
              border-bottom: 2px solid $black; }
            i {
              display: block;
              width: 40px;
              margin: 0 auto;
              margin-bottom: 40px; }
            .desc {
              // margin-left: 110px
              padding-right: 20px;
              .head {
                text-align: center;
                margin-top: -5px;
                margin-bottom: 25px; } } } }
        .note {
          margin-top: 20px;
          ul {
            padding-left: 1em;
            li {
              list-style-type: none;
              position: relative;
              &:before {
                content: "※";
                display: block;
                position: absolute;
                top: 0px;
                left: -1em; } } }
          p {
            margin-top: 20px; } } } }

    section.about {
      background: $black;
      color: $white;
      .inner {
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center; }
      .carousel {
        height: 180px;
        width: 100%;
        display: inline-block;
        margin-top: -60px;
        .slides {
          li {
            width: 305px;
            padding-left: 10px;
            padding-right: 10px;
            img {
              display: block;
              width: 285px; } }

          .slick-prev,
          .slick-next {
            font-size: 0;
            line-height: 0;
            position: absolute;
            top: 50%;
            display: none;
            cursor: pointer;
            color: transparent;
            border: 2px solid $white;
            border-radius: 30px;
            outline: none;
            background: #fff;
            width: 60px;
            height: 60px;
            text-indent: -99999px; }
          .slick-next {
            background: #f0f;
            z-index: 1000;
            right: 10px;

            transition: background-position 0.3s $easeOutQuart;
            background: url(/assets/img/common/caroucel_next.svg) no-repeat calc(100%) center;
            background-size: contain;
            &:hover {
              background: url(/assets/img/common/caroucel_next.svg) no-repeat calc(100% + 5px) center;
              background-size: contain; } }

          .slick-prev {
            background: #ff0;
            left: 10px;
            z-index: 1000;
            transition: background-position 0.3s $easeOutQuart;
            background: url(/assets/img/common/caroucel_prev.svg) no-repeat calc(100%) center;
            background-size: contain;
            &:hover {
              background: url(/assets/img/common/caroucel_prev.svg) no-repeat calc(100% - 5px) center;
              background-size: contain; } } } }
      h3.head {
        text-align: left;
        margin-top: 40px;
        span {
          display: block;
          line-height: 1.0;
          &.headjp {
            margin-top: 14px; } } }
      .statement {
        text-align: left;
        margin-bottom: 40px;
        .big {
          font-size: 70px;
          line-height: 0.9;
          font-weight: 800;
          margin-top: 30px;
          margin-bottom: 30px; }
        .head {
          margin-bottom: 40px; }
        p {
          font-size: 16px;
          margin-bottom: 30px; } }

      .about-blocks {
        text-align: left;
        font-size: 0px;
        padding-bottom: 90px;
        padding-left: 20px;
        padding-right: 20px;
        .block {
          border-top: 4px solid $white;
          vertical-align: top;
          padding-bottom: 40px;
          padding-top: 30px;
          &.block-01 {
            color: $blue;
            border-color: $blue; }
          &.block-02 {
            color: $yellow;
            border-color: $yellow; }
          &.block-03 {
            color: $red;
            border-color: $red; }
          &.block-04 {
            color: $white;
            border-color: $white; }
          .big {
            margin-bottom: 31px;
            font-size: 60px;
            font-weight: 800;
            line-height: 0.9; }
          .head {
            margin-bottom: 40px; } } } }

    section.floormap {
      background: $stone;
      color: $black;
      .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
        .left {
          padding-top: 40px;
          .map-block {
            padding: 40px;
            margin-bottom: 30px;
            &:last-child {
              margin-bottom: 0px; }
            &.funs {
              background: $yellow;
              color: $black; }
            &.ex {
              background: $gray;
              color: $white; }
            &.shop {
              background: $blue;
              color: $white; }
            .logo {
              width: 120px;
              margin-top: 5px; }
            .desc {
              margin-top: 25px; } }

          .btn_funs {
            display: block;
            background: $red;
            line-height: 36px;
            padding-top: 31px;
            padding-bottom: 31px;
            text-align: center;
            color: $white;
            margin-bottom: 30px;
            i {
              width: 36px;
              height: 36px;
              transition: transform 0.3s $easeOutQuart; }
            &:hover {
              opacity: 1.0;
              i {
                transform: translate(0px, -5px); } } } }
        .right {
          img {
            margin-top: 40px;
            width: 100%; }
          .head {
            span {
              display: block;
              line-height: 1.0;
              &.headjp {
                margin-top: 14px; } } } } } }

    section.archive {
      background: $blue;
      color: $white;
      position: relative;
      padding-bottom: 40px;
      .inner {
        padding-top: 40px;
        div.head {
          margin-left: 20px;
          margin-right: 20px;
          padding-bottom: 30px;
          span {
            display: block;
            line-height: 1.0;
            &.headjp {
              margin-top: 14px; } } }
        .archive-blocks {
          text-align: center;
          .archive-list {
            font-size: 0;
            letter-spacing: -300em;
            display: inline-block;
            li {
              margin-bottom: 40px;
              display: inline-block;
              width: calc(100% - 40px);
              max-width: 335px;
              margin-left: 20px;
              margin-right: 20px;
              vertical-align: top;
              position: relative;
              text-align: left;
              transition: opacity 0.3s $easeOutQuart;
              &.hover {
                opacity: 0.7; }
              a {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%; }
              span {
                display: block;
                &.date {
                  margin-top: 10px; }
                &.title {
                  margin-top: 4px; } }
              figure {
                width: calc(100vw - 40px);
                height: calc(100vw - 40px);

                max-width: 335px;
                max-height: 335px;

                background: $stone;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                display: block;
                figcaption {
                  color: $black;
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  width: 100px;
                  height: 33px;
                  line-height: 33px;
                  background: $yellow; }
                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 40px); } } } } } }
      a.btn_more {
        margin-left: 20px;
        margin-right: 20px;
        display: block;
        background: $red;
        letter-spacing: 0.3em;
        line-height: 36px;
        padding-top: 31px;
        padding-bottom: 31px;
        text-align: center;
        i {
          width: 36px;
          height: 36px;
          transition: transform 0.3s $easeOutQuart; }
        &:hover {
          opacity: 1.0;
          i {
            transform: translate(10px, 0px); } } } }

    section.ex-kv {
      .inner {
        img {
          width: 100%; } } }

    section.ex-summaly {
      .inner {
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        // text-align: center
        .showing {
          padding-top: 40px;
          .head {
            span {
              display: block;
              line-height: 1.0;
              &.headjp {
                margin-top: 14px; } } }

          .ex-title {
 }            // font-size: 16px

          .date {
            font-size: 20px;
            font-weight: 600;
            margin-top: 20px; }

          .desc {
            margin-top: 40px; } }

        nav {
          margin-top: 40px;
          margin-bottom: 40px;
          li {
            border-top: 2px solid $black;
            position: relative;
            list-style-type: none;
            margin-right: -20px;
            &:last-child {
              border-bottom: 2px solid $black; }
            span {
              display: block;
              padding-right: 60px; }
            a {
              padding-top: 26px;
              padding-bottom: 26px;
              transition: background-position 0.3s $easeOutQuart;
              display: block;
              background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 20px) center;
              background-size: 36px 36px;
              &:hover {
                opacity: 1.0;
                background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 10px) center;
                background-size: 36px 36px; } } } } } }

    section.ex-outline {
      background: $blue;
      color: $white;
      padding-top: 40px;
      padding-bottom: 60px;
      .inner {
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        h2 {
          margin-bottom: 40px;
          line-height: 1.0; }
        .info {
          margin-bottom: 30px;
          ul {
            li {
              list-style-type: none;
              margin-bottom: 15px;
              span {
                display: block;
                vertical-align: top;
                &.head {
                  width: 110px; }
                &.head-l {
                  width: 180px; } } } }

          p {
            margin-bottom: 15px;
            span {
              display: inline-block;
              vertical-align: top;
              &.head {
                width: 110px; }
              &.head-l {
                width: 180px; } } } }
        .note {
          ul {
            padding-left: 1em;
            li {
              list-style-type: none;
              &:before {
                content: "※";
                display: block;
                position: absolute;
                top: 0px;
                left: -1em; }
              position: relative;
              margin-bottom: 20px; } }
          p {
            margin-bottom: 20px; } }


        .btn_map {
          color: $white;
          display: block;
          background: $red;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s $easeOutQuart; }
          &:hover {
            opacity: 1.0;
            i {
              transform: translate(0px, -5px); } } }
        .left {
          position: relative;
          box-sizing: border-box; }
        .right {
          margin-top: 40px;
          position: relative; } } }

    section.ex-about {
      background: $yellow;
      color: $black;
      padding-bottom: 80px;
      .inner {
        position: relative;
        h2 {
          margin-bottom: 40px;
          line-height: 1.0; }
        .left {
          position: relative;
          box-sizing: border-box;
          padding-left: 20px;
          padding-right: 20px;
          margin-top: 40px;
          .txt_about {
            font-size: 16px; } }

        .right {
          padding-left: 55px;
          font-size: 0px;
          z-index: 1;
          figure {
            margin-top: -40px;
            display: inline-block;
            img {
              width: 100%;
              margin-bottom: 20px;
              &:last-child {
                margin-bottom: 0px; } } } } } }

    section.ex-highlights {
      background: $stone;
      color: $black;
      padding-top: 40px;
      padding-bottom: 40px;
      .inner {
        padding-left: 20px;
        padding-right: 20px;
        h2 {
          margin-bottom: 40px;
          line-height: 1.0; }
        .highlights {
          .item {
            margin-bottom: 30px;
            img {
              width: 100%; }
            span {
              font-size: 16px;
              display: block;
              width: 100%;
              margin-top: 10px;
              a {
                text-decoration: underline; } } } } } }

    section.ex-goods {
      background: $blue;
      color: $white;
      position: relative;
      .inner {
        padding-top: 40px;
        div.head {
          padding-left: 20px;
          padding-right: 20px;
          span {
            display: block;
            line-height: 1.0;
            &.headjp {
              margin-top: 14px; } } }
        .goods-blocks {
          padding-top: 40px;
          text-align: center;
          .goods-list {
            font-size: 0;
            letter-spacing: -300em;
            display: inline-block;
            li {
              margin-bottom: 40px;
              display: inline-block;
              width: calc(100% - 40px);
              max-width: 335px;
              margin-left: 20px;
              margin-right: 20px;
              vertical-align: top;
              position: relative;
              text-align: left;
              transition: opacity 0.3s $easeOutQuart;
              &.hover {
                opacity: 0.7; }
              a {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%; }
              p {
                display: block;
                &.name {
                  margin-top: 10px; }
                &.note {
                  margin-top: 4px; }
                span {
                  font-style: normal;
                  color: $red; } }
              figure {
                width: calc(100vw - 40px);
                height: calc(100vw - 40px);

                max-width: 335px;
                max-height: 335px;

                background: $stone;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                display: block;
                figcaption {
                  color: $black;
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  // width: 110px
                  height: 33px;
                  line-height: 33px;
                  padding-left: 10px;
                  padding-right: 10px;
                  // text-align: center
                  background: $yellow; }
                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 40px); } } } } } } }

    section.ex-copyright {
      background: $stone;
      color: $black;
      position: relative;
      .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px; } }

    section.ex-events {
      color: $black;
      position: relative;
      &:nth-child(odd) {
        background: $stone; }
      &:nth-child(even) {
        background: #E7E7EB; }
      .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
        h2 {
          margin-bottom: 40px; }
        .item {
          margin-bottom: 90px;
          padding: 20px;
          padding-bottom: 90px;
          background: white;
          position: relative;
          h3 {
            margin-bottom: 40px; }
          .link {
            position: absolute;
            bottom: -50px;
            left: 20px;
            a {
              color: $white;
              display: block;
              background: $red;
              line-height: 36px;
              padding-top: 31px;
              padding-bottom: 31px;
              text-align: center;
              width: calc(100vw - 40px);
              i {
                width: 36px;
                height: 36px;
                transition: transform 0.3s $easeOutQuart; }
              &:hover {
                opacity: 1.0;
                i {
                  transform: translate(5px, 0px); } } }
            a[target="_blank"] {
              &:hover {
                i {
                  transform: translate(0px, -5px); } } } } } } }

    section.ex-free {
      color: $black;
      position: relative;
      &:nth-child(odd) {
        background: $stone; }
      &:nth-child(even) {
        background: #E7E7EB; }
      .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
        h2 {
          margin-bottom: 40px; }
        .lead {
          margin-bottom: 40px; }
        .item {
          margin-bottom: 40px;
          &:last-child {
            margin-bottom: 0px; }
          &.item-l {
            .desc {
              width: 100%;
              float: none; } }
          figure {
            margin-bottom: 20px;
            img {
              width: 100%; } }
          .desc {
            h3 {
              margin-bottom: 20px; }
            small {
              display: inline-block;
              font-weight: 600;
              font-size: 12px; } } }
        .link {
          margin-top: 40px;
          a {
            margin-bottom: 40px;
            color: $white;
            display: block;
            background: $red;
            line-height: 36px;
            padding-top: 31px;
            padding-bottom: 31px;
            text-align: center;
            margin: 0 auto;
            width: 100%;
            i {
              width: 36px;
              height: 36px;
              transition: transform 0.3s $easeOutQuart; }
            &:hover {
              opacity: 1.0;
              i {
                transform: translate(5px, 0px); } } }
          a[target="_blank"] {
            &:hover {
              i {
                transform: translate(0px, -5px); } } } } } }

    section.topics-list {
      position: relative;
      background: $yellow;
      color: $black;
      .inner {
        padding-left: 20px;
        padding-top: 40px;
        padding-bottom: 0px;
        h2 {
          margin-bottom: 80px;
          line-height: 1.0; }
        .topcis-area {
          li {
            border-top: 2px solid $black;
            position: relative;
            list-style-type: none;
            span {
              display: block;
              padding-right: 60px;
              font-size: 16px; }
            a {
              padding-top: 26px;
              padding-bottom: 26px;
              display: block;
              transition: background-position 0.3s $easeOutQuart;
              background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 20px) center;
              background-size: 36px 36px;
              &:hover {
                opacity: 1.0;
                background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 10px) center;
                background-size: 36px 36px; } } } } }

      .pagination {
        margin: 0 auto;
        text-align: center;
        font-size: 0;
        letter-spacing: -100em;
        padding-top: 0px;
        padding-bottom: 40px;

        a.btn_next,
        a.btn_prev {
          color: $white;
          display: block;
          background: $red;
          letter-spacing: 0.3em;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s $easeOutQuart; }
          &:hover {
            opacity: 1.0;
            &.btn_prev {
              i {
                transform: translate(-10px, 0px); } }
            &.btn_next {
              i {
                transform: translate(10px, 0px); } } } }
        a.btn_next {
          margin-left: 40px; }

        a.btn_prev {
          margin-right: 40px; }

        .pagination-area {
          display: inline-block;
          padding-top: 30px;
          padding-bottom: 30px;
          li {
            display: inline-block;
            margin-right: 15px;
            width: 40px;
            &:last-child {
              margin-right: 0px; }
            &.current {
              a {
                background: $white;
                color: $black; } }
            a {
              border-radius: 25px;
              display: block;
              line-height: 40px;
              width: 40px;
              height: 40px;
              color: $white;
              background: $black;
              span {
                display: inline-block;
                transition: transform 0.3s $easeOutQuart; }
              &:hover {
                opacity: 1.0;
                text-decoration: none;
                span {
                  transform: translate(0px, -3px); } } } } } } }

    section.topics-detail {
      background: $yellow;
      color: $black;
      padding-top: 40px;
      padding-bottom: 40px;
      .inner {
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        .topics-info {
          .title {
            margin-bottom: 10px; } }
        .topics-body {
          margin-top: 60px;
          p {
            font-size: 16px;
            margin-bottom: 60px;
            &:last-child {
              margin-bottom: 0px; } }
          img {
            display: block;
            width: 100%;
            max-width: 840px;
            margin: 40px auto; } } } }


    section.topics-related {
      background: $blue;
      color: $white;
      position: relative;
      .inner {
        padding-top: 40px;
        h2.head {
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 40px;
          span {
            display: block;
            line-height: 1.0;
            &.headjp {
              margin-top: 14px; } } }
        .related-blocks {
          text-align: center;
          .related-list {
            font-size: 0;
            letter-spacing: -300em;
            display: inline-block;
            li {
              margin-bottom: 40px;
              display: inline-block;
              width: calc(100% - 40px);
              max-width: 335px;
              margin-left: 20px;
              margin-right: 20px;
              vertical-align: top;
              position: relative;
              text-align: left;
              transition: opacity 0.3s $easeOutQuart;
              &.hover {
                opacity: 0.7; }
              a {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%; }
              span {
                display: block;
                &.date {
                  margin-top: 10px; }
                &.title {
                  margin-top: 4px; } }
              figure {
                width: calc(100vw - 40px);
                height: calc(100vw - 40px);

                max-width: 335px;
                max-height: 335px;

                background: $stone;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                display: block;
                figcaption {
                  color: $black;
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  width: 100px;
                  height: 33px;
                  line-height: 33px;
                  background: $yellow; }
                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 40px); } } } } } } }

    section.p404 {
      color: $black;
      position: relative;
      background: $stone;
      .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
        h1 {
          margin-bottom: 40px; }
        p {
          margin-bottom: 40px; }
        a {
          color: $white;
          display: block;
          background: $red;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          margin-left: -20px;
          i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s $easeOutQuart; }
          &:hover {
            opacity: 1.0;
            i {
              transform: translate(5px, 0px); } } } } }

    section.privacy {
      color: $black;
      position: relative;
      background: $stone;
      .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
        h3 {
          margin-bottom: 2em;
          font-weight: 600;
          font-size: 20px; }
        p {
          margin-bottom: 1em; } } } }


  footer {
    background: $black;
    color: $white;
    position: relative;
    padding-bottom: 80px;
    padding-top: 80px;
    .inner {
      padding-left: 20px;
      padding-right: 20px;

      .logo {
        margin-bottom: 70px;
        text-align: center;
        img {
          width: 100%;
          max-width: 280px; } }

      .sns {
        margin-bottom: 30px;
        font-size: 0;
        letter-spacing: -300em;
        a {
          box-sizing: border-box;
          border-radius: 30px;
          border: 2px solid $white;
          display: inline-block;
          width: 60px;
          height: 60px;
          text-align: center;
          margin-right: 20px;
          i {
            width: 36px;
            height: 100%;
            background-size: contain;
            transition: transform 0.3s $easeOutQuart; }
          &:hover {
            opacity: 1.0;
            i {
              transform: scale(1.2, 1.2); } } } }

      .address {
        margin-bottom: 30px; }

      .schedule {
        margin-bottom: 40px;
        ul {
          li {
            list-style-type: none;
            margin-bottom: 20px;
            span {
              display: inline-block;
              vertical-align: top;
              &.head {
                width: 110px; } } } }
        p {
          margin-bottom: 20px;
          span {
            display: inline-block;
            vertical-align: top;
            &.head {
              width: 110px; } } } }
      .copy {
        margin-bottom: 40px; }

      .banners {
        font-size: 0;
        letter-spacing: -300em;
        a {
          display: inline-block;
          margin-bottom: 20px;
          margin-right: 20px;
          width: 185px;
          img {
            width: 100%; } } }

      .btn_up {
        position: absolute;
        bottom: 93px;
        right: 20px;
        display: block;
        width: 90px;
        height: 90px;
        border-radius: 45px;
        background: $yellow;
        img {
          transition: transform 0.3s $easeOutQuart;
          width: 90px; }
        &:hover {
          opacity: 1.0;
          img {
            transform: translate(0px, -5px); } } } } } }


