@charset "UTF-8";
@import url(lib/reset.min.css);
@import url(lib/slick.css);
@import url(lib/slick-theme.css);
@import url(lib/reset.min.css);
@import url(lib/slick.css);
@import url(lib/slick-theme.css);
* {
  margin: 0;
  padding: 0; }

.clearfix {
  zoom: 1; }
  .clearfix:after {
    clear: both;
    display: block;
    content: ""; }

.bold {
  font-weight: 700; }

.red {
  color: #FF3E3E; }

i {
  display: inline-block;
  font-size: 0;
  width: 48px;
  height: 48px;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  i.menu {
    background-image: url(/assets/img/common/icon_menu.svg); }
  i.menu_b {
    background-image: url(/assets/img/common/icon_menu_b.svg); }
  i.close {
    background-image: url(/assets/img/common/icon_close.svg); }
  i.close_b {
    background-image: url(/assets/img/common/icon_close_b.svg); }
  i.tw {
    background-image: url(/assets/img/common/icon_tw.svg); }
  i.tw_b {
    background-image: url(/assets/img/common/icon_tw_b.svg); }
  i.ig {
    background-image: url(/assets/img/common/icon_ig.svg); }
  i.blank {
    background-image: url(/assets/img/common/icon_blank.svg); }
  i.blank_b {
    background-image: url(/assets/img/common/icon_blank_b.svg); }
  i.arrow_w {
    background-image: url(/assets/img/common/icon_arrow_next_w.svg); }
  i.arrow_prev_w {
    background-image: url(/assets/img/common/icon_arrow_prev_w.svg); }
  i.train {
    width: 40px;
    height: 46px;
    background-image: url(/assets/img/common/icon_train.svg); }
  i.bus {
    width: 40px;
    height: 46px;
    background-image: url(/assets/img/common/icon_bus.svg); }

@keyframes img-move {
  from {
    background-position: left top; }
  to {
    background-position: left bottom; } }

@keyframes vertical {
  0% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0); } }

@keyframes blink {
  0% {
    opacity: 0.5; }
  30% {
    opacity: 1.0; } }

@keyframes spin1 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes bloom {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0.9); } }

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB; }

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB; }

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB; }

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local("Poppins Bold"), local("Poppins-Bold"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB; }

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local("Poppins Bold"), local("Poppins-Bold"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local("Poppins Bold"), local("Poppins-Bold"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@media only screen and (min-width: 1280px) {
  .is-sp {
    display: none !important; }
  .is-tb {
    display: none !important; }
  body {
    color: #000;
    background: #FFFFFF;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "palt";
    font-family: "Poppins", "dnp-shuei-gothic-kin-std", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Arial, sans-serif;
    letter-spacing: 0.05em;
    overflow-x: hidden;
    line-height: 1.5;
    font-size: 16px; }
    body.index {
      color: #FFFFFF;
      background: #1A1A1A; }
      body.index header {
        color: #FFFFFF;
        background: #000; }
      body.index #menu {
        color: #FFFFFF;
        background: #000; }
    body.en footer .inner .schedule ul li span.head {
      width: 170px; }
    body.en section.title .inner .head span.headjp {
      letter-spacing: 0em; }
    body.en section.kv .inner .left .showing a {
      letter-spacing: 0em; }
    body.en section.upcoming .inner .right .info a {
      letter-spacing: 0em; }
    body.en section.access .inner .schedule ul li span.head {
      width: 170px; }
    body.en section.access {
      background: #555560;
      color: #FFFFFF; }
      body.en section.access .inner .pub-transport {
        margin-top: 0px;
        position: absolute;
        right: calc(((100vw - 1280px) / 2) * -1);
        top: -60px;
        z-index: 10; }
      body.en section.access .inner .note {
        margin-top: 40px;
        position: static; }
    body.en section.about .statement .head {
      font-weight: 800;
      font-size: 40px; }
    body.en .about-blocks .block .head {
      font-weight: 800;
      margin-bottom: 40px; }
    body.en section.archive .inner .head span.headjp {
      letter-spacing: 0em; }
    body.en section.archive a.btn_more {
      letter-spacing: 0em; }
  a {
    color: inherit;
    text-decoration: none;
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
    a:hover {
      opacity: .6;
      text-decoration: none; }
  ul li {
    list-style-type: none; }
  .type-test .container {
    padding: 100px; }
  .type-test p {
    margin-bottom: 30px; }
  .h1 {
    font-weight: 600;
    font-size: 28px; }
  .h1e {
    font-size: 40px;
    font-weight: 700; }
  .h2 {
    font-weight: 700;
    font-size: 24px; }
  .h3 {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.3em; }
  .h3e {
    font-weight: 600;
    font-size: 20px; }
  .h4 {
    font-weight: 500;
    font-size: 20px; }
  .h4b {
    font-size: 20px;
    font-weight: 600; }
  .h4bb {
    font-size: 20px;
    font-weight: 700; }
  .ui {
    font-weight: 700;
    font-size: 16px; }
  .h5 {
    font-weight: 600;
    font-size: 16px; }
  .h5l {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.3em; }
  .h5m {
    font-weight: 500;
    font-size: 16px; }
  .sub {
    font-weight: 600;
    font-size: 12px; }
  .sube {
    font-size: 12px;
    font-weight: 700; }
  header {
    width: 100%;
    height: 128px;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
    position: fixed;
    top: 0px;
    left: 0px;
    background: #FFFFFF;
    z-index: 1200; }
    header .inner {
      width: 1280px;
      max-width: 1680px;
      margin: 0 auto;
      line-height: 48px;
      position: relative;
      height: 48px;
      overflow: hiden; }
      header .inner .btn_menu {
        position: absolute;
        top: 0px;
        left: -12px; }
      header .inner .btn_close {
        position: absolute;
        top: 0px;
        left: -12px;
        display: none; }
      header .inner .title {
        position: absolute;
        top: 14px;
        left: 220px; }
        header .inner .title img {
          height: 23px; }
      header .inner .twtter {
        position: absolute;
        top: 0px;
        right: 101px; }
      header .inner .lang {
        position: absolute;
        top: 0px;
        right: 0px; }
        header .inner .lang a.current {
          opacity: 0.5; }
  #menu {
    width: 100%;
    position: fixed;
    top: -250px;
    left: 0px;
    z-index: 1100;
    padding-bottom: 72px;
    pointer-events: none;
    transition: top 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    background: #FFFFFF;
    color: #000;
    font-weight: 600; }
    #menu.show {
      top: 128px;
      pointer-events: auto; }
    #menu .inner {
      width: 1280px;
      margin: 0 auto;
      position: relative; }
      #menu .inner nav {
        padding-left: 220px; }
        #menu .inner nav ul {
          float: left;
          width: 290px;
          margin-right: 40px; }
          #menu .inner nav ul:last-child {
            margin-right: 0px; }
          #menu .inner nav ul li {
            font-size: 20px;
            margin-bottom: 4px;
            line-height: 36px; }
            #menu .inner nav ul li a {
              line-height: 36px;
              display: block; }
            #menu .inner nav ul li i {
              width: 36px;
              height: 36px; }
            #menu .inner nav ul li:last-child {
              margin-bottom: 0px; }
  .container {
    padding-top: 128px; }
    .container section.title {
      padding-bottom: 60px; }
      .container section.title .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative; }
        .container section.title .inner .info {
          width: 840px;
          float: right;
          top: 0px;
          left: 440px;
          padding-bottom: 100px; }
          .container section.title .inner .info h1 {
            margin-bottom: 40px; }
        .container section.title .inner .head {
          width: 440px;
          float: left; }
          .container section.title .inner .head .date {
            margin-top: 40px;
            padding-bottom: 100px; }
          .container section.title .inner .head span {
            display: block; }
            .container section.title .inner .head span.headjp {
              margin-top: 14px; }
    .container section.kv {
      box-sizing: border-box; }
      .container section.kv .inner {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        padding-bottom: 80px; }
        .container section.kv .inner .left {
          width: calc(100% - 840px);
          float: left;
          position: relative;
          box-sizing: border-box;
          padding-right: 40px; }
          .container section.kv .inner .left h1 {
            margin-top: 80px; }
            .container section.kv .inner .left h1 img {
              width: 100%; }
          .container section.kv .inner .left .showing {
            padding-right: 40px;
            margin-bottom: 40px; }
            .container section.kv .inner .left .showing:before {
              content: "";
              display: block;
              height: calc(100vh - 920px);
              min-height: 120px;
              max-height: calc(100vw - 1000px); }
            .container section.kv .inner .left .showing .head span {
              display: block;
              line-height: 1.0; }
              .container section.kv .inner .left .showing .head span.headjp {
                margin-top: 14px; }
            .container section.kv .inner .left .showing .ex-title {
              margin-top: 60px; }
            .container section.kv .inner .left .showing .date {
              margin-top: 20px; }
            .container section.kv .inner .left .showing .desc {
              margin-top: 40px;
              margin-bottom: 60px; }
            .container section.kv .inner .left .showing a {
              color: #FFFFFF;
              display: block;
              background: #FF3E3E;
              letter-spacing: 0.3em;
              line-height: 36px;
              padding-top: 31px;
              padding-bottom: 31px;
              text-align: center;
              margin-left: calc((((100vw - 1280px) / 2)) * -1);
              width: calc((((100vw - 1280px) / 2)) + 100% + 40px); }
              .container section.kv .inner .left .showing a i {
                width: 36px;
                height: 36px;
                transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
              .container section.kv .inner .left .showing a:hover {
                opacity: 1.0; }
                .container section.kv .inner .left .showing a:hover i {
                  transform: translate(10px, 0px); }
        .container section.kv .inner .right {
          width: 840px;
          float: right;
          position: relative; }
          .container section.kv .inner .right img {
            width: calc((((100vw - 1280px) / 2))+ 100%);
            max-width: calc(((1650px - 1280px) / 2) + 100%); }
    .container section.upcoming {
      background: #2668FF;
      color: #FFFFFF; }
      .container section.upcoming .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        padding-bottom: 80px; }
        .container section.upcoming .inner .left {
          width: 440px;
          float: left;
          position: relative;
          box-sizing: border-box;
          padding-right: 40px; }
          .container section.upcoming .inner .left img {
            width: calc(100% + 40px);
            margin-top: -80px;
            margin-left: -40px;
            border: 5px solid #2668FF;
            box-sizing: border-box; }
        .container section.upcoming .inner .right {
          width: 840px;
          float: right;
          position: relative; }
          .container section.upcoming .inner .right .head {
            margin-top: 90px; }
            .container section.upcoming .inner .right .head span {
              display: block;
              line-height: 1.0; }
              .container section.upcoming .inner .right .head span.headjp {
                margin-top: 14px; }
          .container section.upcoming .inner .right .info {
            position: relative; }
            .container section.upcoming .inner .right .info .ex-title {
              margin-top: 40px;
              width: 400px; }
            .container section.upcoming .inner .right .info .date {
              margin-top: 20px; }
            .container section.upcoming .inner .right .info a {
              color: #FFFFFF;
              position: absolute;
              top: 0px;
              right: 0px;
              display: block;
              background: #FF3E3E;
              letter-spacing: 0.3em;
              line-height: 36px;
              padding-top: 31px;
              padding-bottom: 31px;
              text-align: center;
              width: calc(((100vw - 1280px) / 2) + 400px);
              margin-right: calc(((100vw - 1280px) / 2) * -1); }
              .container section.upcoming .inner .right .info a i {
                width: 36px;
                height: 36px;
                transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
              .container section.upcoming .inner .right .info a:hover {
                opacity: 1.0; }
                .container section.upcoming .inner .right .info a:hover i {
                  transform: translate(10px, 0px); }
    .container section.pr {
      background: #EEEEF2;
      color: #000; }
      .container section.pr .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        padding-top: 80px;
        padding-bottom: 80px; }
        .container section.pr .inner ul {
          font-size: 0px;
          letter-spacing: -100em; }
          .container section.pr .inner ul li {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 620px;
            margin-bottom: 40px;
            transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
            letter-spacing: 0.0em; }
            .container section.pr .inner ul li.hover {
              opacity: 0.7; }
            .container section.pr .inner ul li:nth-child(even) {
              margin-left: 40px; }
            .container section.pr .inner ul li figure {
              position: relative; }
              .container section.pr .inner ul li figure figcaption {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 100px;
                height: 33px;
                line-height: 33px;
                text-align: center;
                background: #FAE100; }
            .container section.pr .inner ul li .title {
              display: block;
              width: 100%;
              background: #555560;
              box-sizing: border-box;
              padding-left: 40px;
              padding-right: 40px;
              padding-top: 32px;
              padding-bottom: 32px;
              line-height: 36px;
              color: #FFFFFF; }
              .container section.pr .inner ul li .title i {
                width: 36px;
                height: 36px; }
            .container section.pr .inner ul li a {
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%; }
    .container section.topics {
      position: relative; }
      .container section.topics .inner {
        background: #FAE100;
        color: #000;
        width: calc(((100% - 1280px) / 2) + 620px);
        position: absolute;
        right: 0px;
        top: -60px;
        z-index: 1; }
        .container section.topics .inner .head {
          margin-top: 30px;
          margin-left: 40px; }
          .container section.topics .inner .head span {
            display: block;
            line-height: 1.0; }
            .container section.topics .inner .head span.headjp {
              margin-top: 14px; }
        .container section.topics .inner .topcis-list {
          margin-left: 40px;
          margin-top: 40px; }
          .container section.topics .inner .topcis-list li {
            border-top: 2px solid #000;
            position: relative; }
            .container section.topics .inner .topcis-list li span {
              display: block;
              width: 582px; }
            .container section.topics .inner .topcis-list li a {
              padding-top: 26px;
              padding-bottom: 26px;
              display: block;
              transition: background-position 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
              background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 40px) center;
              background-size: 36px 36px; }
              .container section.topics .inner .topcis-list li a:hover {
                opacity: 1.0;
                background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 30px) center;
                background-size: 36px 36px; }
        .container section.topics .inner a.btn_more {
          display: block;
          background: #FF3E3E;
          letter-spacing: 0.3em;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          width: 100%;
          color: #FFFFFF; }
          .container section.topics .inner a.btn_more i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
          .container section.topics .inner a.btn_more:hover {
            opacity: 1.0; }
            .container section.topics .inner a.btn_more:hover i {
              transform: translate(10px, 0px); }
    .container section.access {
      background: #2668FF;
      color: #FFFFFF; }
      .container section.access .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 70px;
        padding-bottom: 120px;
        position: relative; }
        .container section.access .inner h3.head span {
          display: block;
          line-height: 1.0; }
          .container section.access .inner h3.head span.headjp {
            margin-top: 14px; }
        .container section.access .inner .address {
          margin-top: 40px;
          margin-bottom: 40px; }
        .container section.access .inner .btn_map {
          color: #FFFFFF;
          display: block;
          background: #FF3E3E;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          margin-left: calc((((100vw - 1280px) / 2)) * -1);
          width: calc((((100vw - 1280px) / 2)) + 400px); }
          .container section.access .inner .btn_map i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
          .container section.access .inner .btn_map:hover {
            opacity: 1.0; }
            .container section.access .inner .btn_map:hover i {
              transform: translate(0px, -5px); }
        .container section.access .inner .schedule {
          width: 620px;
          margin-top: 40px; }
          .container section.access .inner .schedule ul {
            margin-bottom: 20px; }
            .container section.access .inner .schedule ul li {
              list-style-type: none;
              margin-bottom: 20px; }
              .container section.access .inner .schedule ul li span {
                display: inline-block;
                vertical-align: top; }
                .container section.access .inner .schedule ul li span.head {
                  width: 110px; }
            .container section.access .inner .schedule ul span {
              display: inline-block;
              vertical-align: top; }
              .container section.access .inner .schedule ul span.head {
                width: 110px; }
        .container section.access .inner .pub-transport {
          margin-top: 60px;
          margin-left: calc((((100vw - 1280px) / 2)) * -1);
          width: 620px;
          padding-left: calc((((100vw - 1280px) / 2)));
          background: #EEEEF2;
          color: #000; }
          .container section.access .inner .pub-transport .transport {
            position: relative;
            padding-top: 40px;
            padding-bottom: 40px; }
            .container section.access .inner .pub-transport .transport:first-child {
              border-bottom: 2px solid #000; }
            .container section.access .inner .pub-transport .transport i {
              position: absolute;
              top: 40px;
              left: 0px; }
            .container section.access .inner .pub-transport .transport .desc {
              margin-left: 110px; }
              .container section.access .inner .pub-transport .transport .desc .head {
                margin-top: -5px;
                margin-bottom: 25px; }
        .container section.access .inner .note {
          position: absolute;
          bottom: 120px;
          left: 660px;
          width: 620px; }
          .container section.access .inner .note ul {
            padding-left: 1em; }
          .container section.access .inner .note li {
            position: relative;
            margin-top: 20px; }
            .container section.access .inner .note li:before {
              content: "※";
              display: block;
              position: absolute;
              top: 0px;
              left: -1em; }
          .container section.access .inner .note p {
            margin-top: 20px; }
    .container section.about {
      background: #000;
      color: #FFFFFF; }
      .container section.about .carousel {
        height: 760px;
        width: 100%;
        display: inline-block;
        margin-top: -60px; }
        .container section.about .carousel .slides li {
          width: 1210px;
          padding-left: 35px;
          padding-right: 35px; }
          .container section.about .carousel .slides li img {
            display: block;
            width: 1140px; }
        .container section.about .carousel .slides .slick-prev,
        .container section.about .carousel .slides .slick-next {
          font-size: 0;
          line-height: 0;
          position: absolute;
          top: 50%;
          display: none;
          cursor: pointer;
          color: transparent;
          border: 2px solid #FFFFFF;
          border-radius: 30px;
          outline: none;
          background: #fff;
          width: 60px;
          height: 60px;
          text-indent: -99999px;
          z-index: 10; }
        .container section.about .carousel .slides .slick-next {
          background: #f0f;
          right: 50%;
          margin-right: -620px;
          transition: background-position 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          background: url(/assets/img/common/caroucel_next.svg) no-repeat calc(100%) center;
          background-size: contain; }
          .container section.about .carousel .slides .slick-next:hover {
            background: url(/assets/img/common/caroucel_next.svg) no-repeat calc(100% + 5px) center;
            background-size: contain; }
        .container section.about .carousel .slides .slick-prev {
          background: #ff0;
          left: 50%;
          margin-left: -620px;
          transition: background-position 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          background: url(/assets/img/common/caroucel_prev.svg) no-repeat calc(100%) center;
          background-size: contain; }
          .container section.about .carousel .slides .slick-prev:hover {
            background: url(/assets/img/common/caroucel_prev.svg) no-repeat calc(100% - 5px) center;
            background-size: contain; }
      .container section.about .inner {
        width: 1280px;
        margin: 0 auto;
        text-align: center; }
      .container section.about h3.head {
        text-align: center;
        margin-top: 70px; }
        .container section.about h3.head span {
          display: block;
          line-height: 1.0; }
          .container section.about h3.head span.headjp {
            margin-top: 14px; }
      .container section.about .statement {
        margin-bottom: 80px; }
        .container section.about .statement .big {
          font-size: 110px;
          font-weight: 800;
          line-height: 1.0;
          margin-top: 80px;
          margin-bottom: 80px; }
        .container section.about .statement .head {
          margin-bottom: 40px; }
        .container section.about .statement p {
          margin-bottom: 30px; }
      .container section.about .about-blocks {
        text-align: left;
        font-size: 0px;
        padding-bottom: 90px; }
        .container section.about .about-blocks .block {
          width: 620px;
          float: left;
          border-top: 4px solid #FFFFFF;
          vertical-align: top;
          padding-bottom: 40px;
          padding-top: 40px; }
          .container section.about .about-blocks .block:nth-child(even) {
            margin-left: 20px;
            padding-right: calc((((100% - 1280px) / 2))); }
          .container section.about .about-blocks .block:nth-child(odd) {
            margin-right: 20px;
            padding-left: calc((((100% - 1280px) / 2))); }
          .container section.about .about-blocks .block.block-01 {
            color: #2668FF;
            border-color: #2668FF; }
          .container section.about .about-blocks .block.block-02 {
            color: #FAE100;
            border-color: #FAE100; }
          .container section.about .about-blocks .block.block-03 {
            color: #FF3E3E;
            border-color: #FF3E3E; }
          .container section.about .about-blocks .block.block-04 {
            color: #FFFFFF;
            border-color: #FFFFFF; }
          .container section.about .about-blocks .block .big {
            margin-bottom: 31px;
            font-size: 110px;
            font-weight: 800;
            line-height: 98px; }
          .container section.about .about-blocks .block .head {
            margin-bottom: 40px; }
    .container section.floormap {
      background: #EEEEF2;
      color: #000; }
      .container section.floormap .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px; }
        .container section.floormap .inner .left {
          width: 400px;
          float: left;
          margin-top: -120px; }
          .container section.floormap .inner .left .map-block {
            padding: 40px;
            margin-bottom: 30px; }
            .container section.floormap .inner .left .map-block:last-child {
              margin-bottom: 0px; }
            .container section.floormap .inner .left .map-block.funs {
              background: #FAE100;
              color: #000; }
            .container section.floormap .inner .left .map-block.ex {
              background: #555560;
              color: #FFFFFF; }
            .container section.floormap .inner .left .map-block.shop {
              background: #2668FF;
              color: #FFFFFF; }
            .container section.floormap .inner .left .map-block .logo {
              width: 180px;
              margin-top: 5px; }
            .container section.floormap .inner .left .map-block .desc {
              margin-top: 25px; }
          .container section.floormap .inner .left .btn_funs {
            display: block;
            background: #FF3E3E;
            line-height: 36px;
            padding-top: 31px;
            padding-bottom: 31px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 30px; }
            .container section.floormap .inner .left .btn_funs i {
              width: 36px;
              height: 36px;
              transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
            .container section.floormap .inner .left .btn_funs:hover {
              opacity: 1.0; }
              .container section.floormap .inner .left .btn_funs:hover i {
                transform: translate(0px, -5px); }
        .container section.floormap .inner .right {
          width: 840px;
          float: right; }
          .container section.floormap .inner .right img {
            margin-top: 40px;
            width: 100%; }
          .container section.floormap .inner .right .head span {
            display: block;
            line-height: 1.0; }
            .container section.floormap .inner .right .head span.headjp {
              margin-top: 14px; }
    .container section.archive {
      background: #2668FF;
      color: #FFFFFF;
      position: relative; }
      .container section.archive .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px; }
        .container section.archive .inner .head span {
          display: block;
          line-height: 1.0; }
          .container section.archive .inner .head span.headjp {
            margin-top: 14px; }
        .container section.archive .inner .archive-blocks {
          width: 1280px;
          margin-top: 50px; }
          .container section.archive .inner .archive-blocks .archive-list {
            font-size: 0;
            letter-spacing: -300em; }
            .container section.archive .inner .archive-blocks .archive-list li {
              width: 400px;
              margin-right: 40px;
              margin-bottom: 40px;
              display: inline-block;
              vertical-align: top;
              position: relative;
              transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
              letter-spacing: 0.5em; }
              .container section.archive .inner .archive-blocks .archive-list li.hover {
                opacity: 0.7; }
              .container section.archive .inner .archive-blocks .archive-list li:nth-child(3n) {
                margin-right: 0px; }
              .container section.archive .inner .archive-blocks .archive-list li a {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%; }
              .container section.archive .inner .archive-blocks .archive-list li span {
                display: block; }
                .container section.archive .inner .archive-blocks .archive-list li span.date {
                  margin-top: 10px; }
                .container section.archive .inner .archive-blocks .archive-list li span.title {
                  margin-top: 4px; }
              .container section.archive .inner .archive-blocks .archive-list li figure {
                width: 400px;
                height: 400px;
                background: #EEEEF2;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                display: block; }
                .container section.archive .inner .archive-blocks .archive-list li figure figcaption {
                  color: #000;
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  width: 100px;
                  height: 33px;
                  line-height: 33px;
                  background: #FAE100; }
                .container section.archive .inner .archive-blocks .archive-list li figure img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 40px); }
      .container section.archive a.btn_more {
        display: block;
        background: #FF3E3E;
        letter-spacing: 0.3em;
        line-height: 36px;
        padding-top: 31px;
        padding-bottom: 31px;
        text-align: center;
        color: #FFFFFF;
        width: calc(((100% - 1280px) / 2) + 400px);
        position: absolute;
        bottom: -40px;
        right: 0px;
        z-index: 1; }
        .container section.archive a.btn_more i {
          width: 36px;
          height: 36px;
          transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
        .container section.archive a.btn_more:hover {
          opacity: 1.0; }
          .container section.archive a.btn_more:hover i {
            transform: translate(10px, 0px); }
    .container section.ex-kv {
      background: #EEEEF2; }
      .container section.ex-kv .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        text-align: center; }
        .container section.ex-kv .inner img {
          margin-top: -80px;
          margin-bottom: -80px;
          display: inline-block;
          width: 840px; }
    .container section.ex-outline {
      background: #2668FF;
      color: #FFFFFF;
      padding-top: 160px;
      padding-bottom: 80px; }
      .container section.ex-outline .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative; }
        .container section.ex-outline .inner h2 {
          margin-bottom: 40px;
          line-height: 1.0; }
        .container section.ex-outline .inner .info {
          margin-bottom: 40px; }
          .container section.ex-outline .inner .info ul li {
            margin-bottom: 15px; }
            .container section.ex-outline .inner .info ul li span {
              display: inline-block;
              vertical-align: top; }
              .container section.ex-outline .inner .info ul li span.head {
                width: 220px; }
          .container section.ex-outline .inner .info p {
            margin-bottom: 15px; }
            .container section.ex-outline .inner .info p span {
              display: inline-block;
              vertical-align: top; }
              .container section.ex-outline .inner .info p span.head {
                width: 220px; }
        .container section.ex-outline .inner .note ul {
          padding-left: 1em; }
          .container section.ex-outline .inner .note ul li {
            position: relative;
            margin-bottom: 20px; }
            .container section.ex-outline .inner .note ul li:before {
              content: "※";
              display: block;
              position: absolute;
              top: 0px;
              left: -1em; }
        .container section.ex-outline .inner .note p {
          margin-bottom: 20px; }
        .container section.ex-outline .inner .btn_map {
          display: block;
          background: #FF3E3E;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          margin-left: calc((((100vw - 1280px) / 2)) * -1);
          width: calc((((100vw - 1280px) / 2)) + 510px); }
          .container section.ex-outline .inner .btn_map i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
          .container section.ex-outline .inner .btn_map:hover {
            opacity: 1.0; }
            .container section.ex-outline .inner .btn_map:hover i {
              transform: translate(0px, -5px); }
        .container section.ex-outline .inner .left {
          width: 730px;
          float: left;
          position: relative;
          box-sizing: border-box; }
        .container section.ex-outline .inner .right {
          width: 510px;
          float: right;
          position: relative; }
    .container section.ex-about {
      background: #FAE100;
      color: #000;
      padding-top: 80px;
      padding-bottom: 80px; }
      .container section.ex-about .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative; }
        .container section.ex-about .inner h2 {
          margin-bottom: 40px;
          line-height: 1.0; }
        .container section.ex-about .inner .left {
          width: 840px;
          float: left;
          position: relative;
          box-sizing: border-box; }
        .container section.ex-about .inner .right {
          width: 400px;
          position: absolute;
          top: -160px;
          right: 0px;
          font-size: 0px;
          z-index: 1; }
          .container section.ex-about .inner .right figure {
            width: 100%; }
            .container section.ex-about .inner .right figure img {
              width: 100%;
              margin-bottom: 40px; }
              .container section.ex-about .inner .right figure img:last-child {
                margin-bottom: 0px; }
    .container section.ex-highlights {
      background: #EEEEF2;
      color: #000;
      padding-top: 80px;
      padding-bottom: 200px; }
      .container section.ex-highlights .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative; }
        .container section.ex-highlights .inner h2 {
          margin-bottom: 80px;
          line-height: 1.0; }
        .container section.ex-highlights .inner .highlights .item {
          width: 510px;
          margin-bottom: 60px;
          display: inline-block;
          vertical-align: top; }
          .container section.ex-highlights .inner .highlights .item:nth-child(2n) {
            margin-left: 40px;
            transform: translate(0px, 130px); }
          .container section.ex-highlights .inner .highlights .item:last-child {
            margin-bottom: 0px; }
          .container section.ex-highlights .inner .highlights .item img {
            width: 100%;
            margin-bottom: 30px; }
          .container section.ex-highlights .inner .highlights .item span {
            display: block;
            width: 100%;
            margin-top: 10px; }
            .container section.ex-highlights .inner .highlights .item span a {
              text-decoration: underline; }
    .container section.ex-goods {
      background: #2668FF;
      color: #FFFFFF;
      position: relative; }
      .container section.ex-goods .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px; }
        .container section.ex-goods .inner .head span {
          display: block;
          line-height: 1.0; }
          .container section.ex-goods .inner .head span.headjp {
            margin-top: 14px; }
        .container section.ex-goods .inner .goods-blocks {
          width: 1280px;
          margin-top: 50px; }
          .container section.ex-goods .inner .goods-blocks .goods-list {
            font-size: 0;
            letter-spacing: -300em; }
            .container section.ex-goods .inner .goods-blocks .goods-list li {
              letter-spacing: 0.05em;
              width: 400px;
              margin-right: 40px;
              margin-bottom: 40px;
              display: inline-block;
              vertical-align: top;
              position: relative;
              transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
              .container section.ex-goods .inner .goods-blocks .goods-list li.hover {
                opacity: 0.7; }
              .container section.ex-goods .inner .goods-blocks .goods-list li:nth-child(3n) {
                margin-right: 0px; }
              .container section.ex-goods .inner .goods-blocks .goods-list li a {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%; }
              .container section.ex-goods .inner .goods-blocks .goods-list li p.name {
                margin-top: 10px; }
              .container section.ex-goods .inner .goods-blocks .goods-list li p.note {
                margin-top: 4px; }
              .container section.ex-goods .inner .goods-blocks .goods-list li p span {
                font-style: normal;
                color: #FF3E3E; }
              .container section.ex-goods .inner .goods-blocks .goods-list li figure {
                width: 400px;
                height: 400px;
                background: #EEEEF2;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative; }
                .container section.ex-goods .inner .goods-blocks .goods-list li figure figcaption {
                  color: #000;
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  height: 33px;
                  line-height: 33px;
                  padding-left: 10px;
                  padding-right: 10px;
                  background: #FAE100; }
                .container section.ex-goods .inner .goods-blocks .goods-list li figure img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 40px); }
    .container section.ex-copyright {
      background: #EEEEF2;
      color: #000;
      position: relative; }
      .container section.ex-copyright .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px; }
    .container section.ex-events {
      color: #000;
      position: relative; }
      .container section.ex-events:nth-child(odd) {
        background: #EEEEF2; }
      .container section.ex-events:nth-child(even) {
        background: #E7E7EB; }
      .container section.ex-events .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px; }
        .container section.ex-events .inner h2 {
          margin-bottom: 80px; }
        .container section.ex-events .inner .item {
          margin-bottom: 130px;
          padding: 80px;
          background: white;
          position: relative; }
          .container section.ex-events .inner .item h3 {
            margin-bottom: 40px; }
          .container section.ex-events .inner .item .link {
            position: absolute;
            bottom: -50px;
            right: 0px;
            margin-right: calc( ((100vw - 1280px) * -0.5 )); }
            .container section.ex-events .inner .item .link a {
              color: #FFFFFF;
              display: block;
              background: #FF3E3E;
              line-height: 36px;
              padding-top: 31px;
              padding-bottom: 31px;
              text-align: center;
              width: 50vw; }
              .container section.ex-events .inner .item .link a i {
                width: 36px;
                height: 36px;
                transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
              .container section.ex-events .inner .item .link a:hover {
                opacity: 1.0; }
                .container section.ex-events .inner .item .link a:hover i {
                  transform: translate(5px, 0px); }
            .container section.ex-events .inner .item .link a[target="_blank"]:hover i {
              transform: translate(0px, -5px); }
    .container section.ex-free {
      color: #000;
      position: relative; }
      .container section.ex-free:nth-child(odd) {
        background: #EEEEF2; }
      .container section.ex-free:nth-child(even) {
        background: #E7E7EB; }
      .container section.ex-free .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px; }
        .container section.ex-free .inner h2 {
          margin-bottom: 80px; }
        .container section.ex-free .inner .lead {
          margin-bottom: 80px; }
        .container section.ex-free .inner .item {
          margin-bottom: 80px; }
          .container section.ex-free .inner .item:last-child {
            margin-bottom: 0px; }
          .container section.ex-free .inner .item.item-l .desc {
            width: 100%;
            float: none; }
          .container section.ex-free .inner .item figure {
            width: 440px;
            float: left; }
            .container section.ex-free .inner .item figure img {
              width: 400px; }
          .container section.ex-free .inner .item .desc {
            width: 840px;
            float: left; }
            .container section.ex-free .inner .item .desc h3 {
              margin-bottom: 40px; }
            .container section.ex-free .inner .item .desc small {
              display: inline-block;
              font-weight: 600;
              font-size: 12px; }
        .container section.ex-free .inner .link {
          margin-top: 80px; }
          .container section.ex-free .inner .link a {
            margin-bottom: 40px;
            color: #FFFFFF;
            display: block;
            background: #FF3E3E;
            line-height: 36px;
            padding-top: 31px;
            padding-bottom: 31px;
            text-align: center;
            margin: 0 auto;
            width: 700px; }
            .container section.ex-free .inner .link a i {
              width: 36px;
              height: 36px;
              transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
            .container section.ex-free .inner .link a:hover {
              opacity: 1.0; }
              .container section.ex-free .inner .link a:hover i {
                transform: translate(5px, 0px); }
          .container section.ex-free .inner .link a[target="_blank"]:hover i {
            transform: translate(0px, -5px); }
    .container section.topics-list {
      background: #FAE100;
      color: #000;
      padding-top: 80px;
      padding-bottom: 60px; }
      .container section.topics-list .inner {
        width: calc((((100% - 1280px) / 2)) + 1280px);
        margin-left: calc((((100% - 1280px) / 2)));
        position: relative; }
        .container section.topics-list .inner h2 {
          margin-bottom: 80px;
          line-height: 1.0; }
        .container section.topics-list .inner .topcis-area li {
          border-top: 2px solid #000;
          position: relative; }
          .container section.topics-list .inner .topcis-area li span {
            display: block;
            width: 1210px; }
          .container section.topics-list .inner .topcis-area li a {
            display: block;
            width: 1280px;
            padding-top: 26px;
            padding-bottom: 26px;
            transition: background-position 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
            background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 10px) center;
            background-size: 36px 36px; }
            .container section.topics-list .inner .topcis-area li a:hover {
              opacity: 1.0;
              background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100%) center;
              background-size: 36px 36px; }
      .container section.topics-list .pagination {
        width: 1280px;
        margin: 0 auto;
        text-align: center;
        font-size: 0;
        letter-spacing: -100em;
        padding-top: 80px;
        padding-bottom: 80px; }
        .container section.topics-list .pagination .pagination-area {
          display: inline-block; }
          .container section.topics-list .pagination .pagination-area li {
            display: inline-block;
            margin-right: 20px;
            width: 50px; }
            .container section.topics-list .pagination .pagination-area li:last-child {
              margin-right: 0px; }
            .container section.topics-list .pagination .pagination-area li.current a {
              background: #FFFFFF;
              color: #000; }
            .container section.topics-list .pagination .pagination-area li a {
              border-radius: 25px;
              display: block;
              line-height: 50px;
              width: 50px;
              height: 50px;
              color: #FFFFFF;
              background: #000; }
              .container section.topics-list .pagination .pagination-area li a span {
                display: inline-block;
                transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
              .container section.topics-list .pagination .pagination-area li a:hover {
                opacity: 1.0;
                text-decoration: none; }
                .container section.topics-list .pagination .pagination-area li a:hover span {
                  transform: translate(0px, -5px); }
    .container section.topics-detail {
      background: #FAE100;
      color: #000;
      padding-top: 80px;
      padding-bottom: 80px; }
      .container section.topics-detail .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative; }
        .container section.topics-detail .inner .topics-info .title {
          margin-bottom: 10px; }
        .container section.topics-detail .inner .topics-body {
          margin-top: 60px; }
          .container section.topics-detail .inner .topics-body p {
            margin-bottom: 60px; }
            .container section.topics-detail .inner .topics-body p:last-child {
              margin-bottom: 0px; }
          .container section.topics-detail .inner .topics-body img {
            display: block;
            max-width: 840px;
            margin: 40px auto; }
    .container section.topics-related {
      background: #2668FF;
      color: #FFFFFF;
      position: relative; }
      .container section.topics-related .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px; }
        .container section.topics-related .inner .head span {
          display: block;
          line-height: 1.0; }
          .container section.topics-related .inner .head span.headjp {
            margin-top: 14px; }
        .container section.topics-related .inner .related-blocks {
          width: 1320px;
          margin-left: -20px;
          margin-right: -20px;
          margin-top: 50px; }
          .container section.topics-related .inner .related-blocks .related-list {
            font-size: 0;
            letter-spacing: -300em; }
            .container section.topics-related .inner .related-blocks .related-list li {
              letter-spacing: -0.5em;
              width: 400px;
              margin-left: 20px;
              margin-right: 20px;
              margin-bottom: 40px;
              display: inline-block;
              vertical-align: top;
              position: relative;
              transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
              .container section.topics-related .inner .related-blocks .related-list li.hover {
                opacity: 0.7; }
              .container section.topics-related .inner .related-blocks .related-list li a {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%; }
              .container section.topics-related .inner .related-blocks .related-list li span {
                display: block; }
                .container section.topics-related .inner .related-blocks .related-list li span i {
                  width: 30px;
                  height: 30px;
                  vertical-align: -8px; }
                .container section.topics-related .inner .related-blocks .related-list li span.title {
                  margin-top: 10px; }
              .container section.topics-related .inner .related-blocks .related-list li figure {
                width: 400px;
                height: 400px;
                background: #EEEEF2;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative; }
                .container section.topics-related .inner .related-blocks .related-list li figure figcaption {
                  color: #000;
                  position: absolute;
                  top: 0px;
                  right: 0px;
                  width: 100px;
                  height: 33px;
                  line-height: 33px;
                  background: #FAE100; }
                .container section.topics-related .inner .related-blocks .related-list li figure img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 40px); }
    .container section.next-prev {
      position: relative; }
      .container section.next-prev a {
        color: #FFFFFF;
        display: block;
        background: #FF3E3E;
        line-height: 36px;
        padding-top: 31px;
        padding-bottom: 31px;
        text-align: center;
        width: calc((((100% - 1280px) / 2)) + 620px);
        position: absolute;
        z-index: 1; }
        .container section.next-prev a.btn_prev {
          top: -60px;
          left: 0px; }
        .container section.next-prev a.btn_next {
          top: -60px;
          right: 0px; }
        .container section.next-prev a i {
          width: 36px;
          height: 36px;
          transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
        .container section.next-prev a:hover {
          opacity: 1.0; }
          .container section.next-prev a:hover.btn_prev i {
            transform: translate(-10px, 0px); }
          .container section.next-prev a:hover.btn_next i {
            transform: translate(10px, 0px); }
    .container section.privacy {
      color: #000;
      position: relative;
      background: #EEEEF2; }
      .container section.privacy .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px; }
        .container section.privacy .inner h3 {
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 60px; }
        .container section.privacy .inner p {
          margin-bottom: 1em; }
    .container section.p404 {
      color: #000;
      position: relative;
      background: #EEEEF2; }
      .container section.p404 .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px; }
        .container section.p404 .inner h1 {
          margin-bottom: 60px; }
        .container section.p404 .inner p {
          margin-bottom: 60px; }
        .container section.p404 .inner a {
          color: #FFFFFF;
          display: block;
          background: #FF3E3E;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          margin-left: calc((((100vw - 1280px) / 2)) * -1);
          width: calc((((100vw - 1280px) / 2)) + 400px); }
          .container section.p404 .inner a i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
          .container section.p404 .inner a:hover {
            opacity: 1.0; }
            .container section.p404 .inner a:hover i {
              transform: translate(5px, 0px); }
    .container footer {
      background: #000;
      color: #FFFFFF;
      position: relative;
      padding-bottom: 120px;
      padding-top: 80px; }
      .container footer .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative; }
        .container footer .inner .logo {
          margin-bottom: 60px; }
        .container footer .inner .sns {
          margin-bottom: 30px;
          font-size: 0;
          letter-spacing: -300em; }
          .container footer .inner .sns a {
            box-sizing: border-box;
            border-radius: 30px;
            border: 2px solid #FFFFFF;
            display: inline-block;
            width: 60px;
            height: 60px;
            text-align: center;
            margin-right: 20px; }
            .container footer .inner .sns a i {
              width: 36px;
              height: 100%;
              background-size: contain;
              transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
            .container footer .inner .sns a:hover {
              opacity: 1.0; }
              .container footer .inner .sns a:hover i {
                transform: scale(1.2, 1.2); }
        .container footer .inner .address {
          margin-bottom: 20px; }
        .container footer .inner .schedule {
          margin-bottom: 40px; }
          .container footer .inner .schedule ul li {
            list-style-type: none;
            margin-bottom: 20px; }
            .container footer .inner .schedule ul li span {
              display: inline-block;
              vertical-align: top; }
              .container footer .inner .schedule ul li span.head {
                width: 110px; }
          .container footer .inner .schedule p {
            margin-bottom: 20px;
            line-height: 1.0; }
            .container footer .inner .schedule p span {
              display: inline-block;
              vertical-align: top; }
              .container footer .inner .schedule p span.head {
                width: 110px; }
        .container footer .inner .banners {
          font-size: 0;
          letter-spacing: -300em;
          position: absolute;
          bottom: 0px;
          right: 130px; }
          .container footer .inner .banners a {
            display: inline-block;
            margin-left: 20px;
            width: 185px; }
            .container footer .inner .banners a img {
              width: 100%; }
        .container footer .inner .btn_up {
          position: absolute;
          bottom: -20px;
          right: 0px;
          display: block;
          width: 90px;
          height: 90px;
          border-radius: 45px;
          background: #FAE100; }
          .container footer .inner .btn_up img {
            transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
            width: 90px; }
          .container footer .inner .btn_up:hover {
            opacity: 1.0; }
            .container footer .inner .btn_up:hover img {
              transform: translate(0px, -5px); } }

.clearfix {
  zoom: 1; }
  .clearfix:after {
    clear: both;
    display: block;
    content: ""; }

.bold {
  font-weight: 700; }

.red {
  color: #FF3E3E; }

i {
  display: inline-block;
  font-size: 0;
  width: 48px;
  height: 48px;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  i.menu {
    background-image: url(/assets/img/common/icon_menu.svg); }
  i.menu_b {
    background-image: url(/assets/img/common/icon_menu_b.svg); }
  i.close {
    background-image: url(/assets/img/common/icon_close.svg); }
  i.close_b {
    background-image: url(/assets/img/common/icon_close_b.svg); }
  i.tw {
    background-image: url(/assets/img/common/icon_tw.svg); }
  i.tw_b {
    background-image: url(/assets/img/common/icon_tw_b.svg); }
  i.ig {
    background-image: url(/assets/img/common/icon_ig.svg); }
  i.blank {
    background-image: url(/assets/img/common/icon_blank.svg); }
  i.blank_b {
    background-image: url(/assets/img/common/icon_blank_b.svg); }
  i.arrow_w {
    background-image: url(/assets/img/common/icon_arrow_next_w.svg); }
  i.arrow_prev_w {
    background-image: url(/assets/img/common/icon_arrow_prev_w.svg); }
  i.train {
    width: 40px;
    height: 46px;
    background-image: url(/assets/img/common/icon_train.svg); }
  i.bus {
    width: 40px;
    height: 46px;
    background-image: url(/assets/img/common/icon_bus.svg); }

@keyframes img-move {
  from {
    background-position: left top; }
  to {
    background-position: left bottom; } }

@keyframes vertical {
  0% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0); } }

@keyframes blink {
  0% {
    opacity: 0.5; }
  30% {
    opacity: 1.0; } }

@keyframes spin1 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes bloom {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0.9); } }

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB; }

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB; }

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB; }

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local("Poppins Bold"), local("Poppins-Bold"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB; }

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local("Poppins Bold"), local("Poppins-Bold"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local("Poppins Bold"), local("Poppins-Bold"), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@media only screen and (max-width: 1279px) {
  .is-pc {
    display: none !important; }
  .is-tb {
    display: none !important; }
  html {
    overflow-y: scroll; }
  body {
    color: #000;
    background: #FFFFFF;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "palt";
    font-family: "Poppins",   "dnp-shuei-gothic-kin-std", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Arial, sans-serif;
    letter-spacing: 0.05em;
    line-height: 1.5;
    overflow-x: hidden;
    font-size: 14px; }
    body.index {
      color: #FFFFFF;
      background: #1A1A1A; }
      body.index header {
        color: #FFFFFF;
        background: #000; }
      body.index #menu {
        color: #FFFFFF;
        background: #000; }
    body.en footer .inner .schedule ul li span.head {
      width: 170px; }
    body.en section.title .inner .head span.headjp {
      letter-spacing: 0em; }
    body.en section.kv .inner .left .showing a {
      letter-spacing: 0em; }
    body.en section.upcoming .inner .right .info a {
      letter-spacing: 0em; }
    body.en section.access .inner .schedule ul li span.head {
      width: 170px; }
    body.en section.access {
      background: #555560;
      color: #FFFFFF; }
    body.en section.about .statement .head {
      font-weight: 800;
      font-size: 40px;
      line-height: 1.0; }
    body.en section.about .about-blocks .block .head {
      font-weight: 800;
      margin-bottom: 40px;
      line-height: 1.0; }
    body.en section.archive .inner .head span.headjp {
      letter-spacing: 0em; }
    body.en section.archive a.btn_more {
      letter-spacing: 0em; }
  a {
    color: inherit;
    text-decoration: none;
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
    a:hover {
      opacity: .6;
      text-decoration: none; }
  .h1 {
    font-weight: 600;
    font-size: 28px; }
  .h1e {
    font-size: 28px;
    font-weight: 700; }
  .h2 {
    font-weight: 700;
    font-size: 24px; }
  .h3 {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.3em; }
  .h3e {
    font-weight: 600;
    font-size: 20px; }
  .h4 {
    font-weight: 500;
    font-size: 20px; }
  .h4b {
    font-size: 20px;
    font-weight: 600; }
  .h4bb {
    font-size: 20px;
    font-weight: 700; }
  .ui {
    font-weight: 700;
    font-size: 16px; }
  .h5 {
    font-weight: 600;
    font-size: 16px; }
  .h5l {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.3em; }
  .h5m {
    font-weight: 500;
    font-size: 16px; }
  .sub {
    font-weight: 600;
    font-size: 12px; }
  .sube {
    font-size: 12px;
    font-weight: 700; }
  header {
    width: 100%;
    height: 88px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1200;
    background: #FFFFFF; }
    header .inner {
      line-height: 48px;
      position: relative;
      height: 48px; }
      header .inner .btn_menu {
        position: absolute;
        top: 0px;
        left: -12px; }
      header .inner .btn_close {
        position: absolute;
        top: 0px;
        left: -12px;
        display: none; }
      header .inner .twtter {
        position: absolute;
        top: 0px;
        right: 90px; }
      header .inner .lang {
        position: absolute;
        top: 0px;
        right: 0px; }
        header .inner .lang a.current {
          opacity: 0.5; }
  #menu {
    width: 100%;
    position: fixed;
    top: 88px;
    left: 0px;
    background: #FF3E3E;
    z-index: 1100;
    padding-bottom: 0px;
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translate(0px, -100%);
    background: #FFFFFF;
    color: #000; }
    #menu.show {
      transform: translate(0px, 0%);
      pointer-events: auto; }
    #menu .inner {
      position: relative;
      padding-left: 20px;
      padding-right: 20px; }
      #menu .inner nav ul {
        margin-bottom: 40px; }
        #menu .inner nav ul:last-child {
          margin-right: 0px; }
        #menu .inner nav ul li {
          font-size: 20px;
          margin-bottom: 4px;
          line-height: 36px; }
          #menu .inner nav ul li a {
            line-height: 36px;
            display: block; }
          #menu .inner nav ul li i {
            width: 36px;
            height: 36px; }
          #menu .inner nav ul li:last-child {
            margin-bottom: 0px; }
  .container {
    padding-top: 88px; }
    .container section.title {
      padding-bottom: 40px; }
      .container section.title .logo {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        text-align: center; }
        .container section.title .logo img {
          width: 100%;
          max-width: 280px; }
      .container section.title .inner {
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        position: relative; }
        .container section.title .inner .date {
          margin-top: 40px;
          padding-bottom: 100px; }
        .container section.title .inner h1 {
          position: absolute;
          top: 0px;
          left: 440px; }
        .container section.title .inner .head span {
          display: block;
          line-height: 1.0; }
          .container section.title .inner .head span.headjp {
            margin-top: 14px; }
    .container section.kv {
      box-sizing: border-box; }
      .container section.kv .inner {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 80px; }
        .container section.kv .inner .left .showing {
          padding-top: 40px; }
          .container section.kv .inner .left .showing .head span {
            display: block;
            line-height: 1.0; }
            .container section.kv .inner .left .showing .head span.headjp {
              margin-top: 14px; }
          .container section.kv .inner .left .showing .ex-title {
            margin-top: 60px; }
          .container section.kv .inner .left .showing .date {
            font-size: 20px;
            font-weight: 600;
            margin-top: 20px; }
          .container section.kv .inner .left .showing .desc {
            margin-top: 40px;
            margin-bottom: 60px; }
          .container section.kv .inner .left .showing a {
            display: block;
            color: #FFFFFF;
            background: #FF3E3E;
            letter-spacing: 0.3em;
            line-height: 36px;
            padding-top: 31px;
            padding-bottom: 31px;
            text-align: center; }
            .container section.kv .inner .left .showing a i {
              width: 36px;
              height: 36px;
              transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
            .container section.kv .inner .left .showing a:hover {
              opacity: 1.0; }
              .container section.kv .inner .left .showing a:hover i {
                transform: translate(10px, 0px); }
        .container section.kv .inner .right img {
          width: 100vw;
          margin-left: -20px; }
    .container section.upcoming {
      background: #2668FF;
      color: #FFFFFF; }
      .container section.upcoming .inner {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px; }
        .container section.upcoming .inner .left {
          position: relative;
          box-sizing: border-box; }
          .container section.upcoming .inner .left img {
            width: 100%;
            margin-top: -40px;
            border: 5px solid #2668FF;
            box-sizing: border-box; }
        .container section.upcoming .inner .right {
          position: relative; }
          .container section.upcoming .inner .right .head {
            margin-top: 40px; }
            .container section.upcoming .inner .right .head span {
              display: block;
              line-height: 1.0; }
              .container section.upcoming .inner .right .head span.headjp {
                margin-top: 14px; }
          .container section.upcoming .inner .right .info {
            position: relative; }
            .container section.upcoming .inner .right .info .ex-title {
              margin-top: 40px; }
            .container section.upcoming .inner .right .info .date {
              font-size: 20px;
              font-weight: 600;
              margin-top: 30px;
              margin-bottom: 40px; }
            .container section.upcoming .inner .right .info a {
              display: block;
              background: #FF3E3E;
              letter-spacing: 0.3em;
              line-height: 36px;
              padding-top: 31px;
              padding-bottom: 31px;
              text-align: center; }
              .container section.upcoming .inner .right .info a i {
                width: 36px;
                height: 36px;
                transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
              .container section.upcoming .inner .right .info a:hover {
                opacity: 1.0; }
                .container section.upcoming .inner .right .info a:hover i {
                  transform: translate(10px, 0px); }
    .container section.pr {
      background: #EEEEF2;
      color: #000; }
      .container section.pr .inner {
        position: relative;
        padding-top: 40px;
        text-align: center; }
        .container section.pr .inner ul {
          font-size: 0px;
          letter-spacing: -100em;
          display: inline-block; }
          .container section.pr .inner ul li {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 40px);
            max-width: 335px;
            margin-bottom: 40px;
            margin-left: 20px;
            margin-right: 20px;
            text-align: left;
            transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
            .container section.pr .inner ul li.hover {
              opacity: 0.7; }
            .container section.pr .inner ul li figure {
              position: relative; }
              .container section.pr .inner ul li figure img {
                width: 100%; }
              .container section.pr .inner ul li figure figcaption {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 100px;
                height: 33px;
                line-height: 33px;
                text-align: center;
                background: #FAE100; }
            .container section.pr .inner ul li .title {
              display: block;
              width: 100%;
              background: #555560;
              box-sizing: border-box;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 20px;
              padding-bottom: 20px;
              color: #FFFFFF; }
              .container section.pr .inner ul li .title i {
                width: 36px;
                height: 36px; }
            .container section.pr .inner ul li a {
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%; }
    .container section.topics {
      position: relative;
      background: #FAE100;
      color: #000; }
      .container section.topics .inner {
        padding-left: 20px;
        padding-top: 40px;
        padding-bottom: 0px; }
        .container section.topics .inner .head span {
          display: block;
          line-height: 1.0; }
          .container section.topics .inner .head span.headjp {
            margin-top: 14px; }
        .container section.topics .inner .topcis-list {
          margin-top: 40px; }
          .container section.topics .inner .topcis-list li {
            border-top: 2px solid #000;
            position: relative;
            list-style-type: none; }
            .container section.topics .inner .topcis-list li span {
              display: block;
              padding-right: 60px; }
            .container section.topics .inner .topcis-list li a {
              display: block;
              padding-top: 26px;
              padding-bottom: 26px;
              transition: background-position 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
              background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 20px) center;
              background-size: 36px 36px; }
              .container section.topics .inner .topcis-list li a:hover {
                opacity: 1.0;
                background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 10px) center;
                background-size: 36px 36px; }
        .container section.topics .inner a.btn_more {
          color: #FFFFFF;
          display: block;
          background: #FF3E3E;
          letter-spacing: 0.3em;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          margin-right: 20px; }
          .container section.topics .inner a.btn_more i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
          .container section.topics .inner a.btn_more:hover {
            opacity: 1.0; }
            .container section.topics .inner a.btn_more:hover i {
              transform: translate(10px, 0px); }
    .container section.access {
      background: #2668FF;
      color: #FFFFFF; }
      .container section.access .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 80px;
        position: relative; }
        .container section.access .inner h3.head span {
          display: block;
          line-height: 1.0; }
          .container section.access .inner h3.head span.headjp {
            margin-top: 14px; }
        .container section.access .inner .address {
          margin-top: 40px;
          margin-bottom: 40px; }
        .container section.access .inner .btn_map {
          color: #FFFFFF;
          display: block;
          background: #FF3E3E;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          margin-left: -20px; }
          .container section.access .inner .btn_map i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
          .container section.access .inner .btn_map:hover {
            opacity: 1.0; }
            .container section.access .inner .btn_map:hover i {
              transform: translate(0px, -5px); }
        .container section.access .inner .schedule {
          margin-top: 40px; }
          .container section.access .inner .schedule ul li {
            list-style-type: none;
            margin-bottom: 20px; }
            .container section.access .inner .schedule ul li span {
              display: inline-block;
              vertical-align: top; }
              .container section.access .inner .schedule ul li span.head {
                width: 90px; }
          .container section.access .inner .schedule p {
            margin-bottom: 20px; }
            .container section.access .inner .schedule p span {
              display: inline-block;
              vertical-align: top; }
              .container section.access .inner .schedule p span.head {
                width: 90px; }
        .container section.access .inner .pub-transport {
          margin-top: 40px;
          margin-left: -20px;
          padding-left: 20px;
          background: #EEEEF2;
          color: #000; }
          .container section.access .inner .pub-transport .transport {
            position: relative;
            padding-top: 40px;
            padding-bottom: 40px; }
            .container section.access .inner .pub-transport .transport:first-child {
              border-bottom: 2px solid #000; }
            .container section.access .inner .pub-transport .transport i {
              display: block;
              width: 40px;
              margin: 0 auto;
              margin-bottom: 40px; }
            .container section.access .inner .pub-transport .transport .desc {
              padding-right: 20px; }
              .container section.access .inner .pub-transport .transport .desc .head {
                text-align: center;
                margin-top: -5px;
                margin-bottom: 25px; }
        .container section.access .inner .note {
          margin-top: 20px; }
          .container section.access .inner .note ul {
            padding-left: 1em; }
            .container section.access .inner .note ul li {
              list-style-type: none;
              position: relative; }
              .container section.access .inner .note ul li:before {
                content: "※";
                display: block;
                position: absolute;
                top: 0px;
                left: -1em; }
          .container section.access .inner .note p {
            margin-top: 20px; }
    .container section.about {
      background: #000;
      color: #FFFFFF; }
      .container section.about .inner {
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center; }
      .container section.about .carousel {
        height: 180px;
        width: 100%;
        display: inline-block;
        margin-top: -60px; }
        .container section.about .carousel .slides li {
          width: 305px;
          padding-left: 10px;
          padding-right: 10px; }
          .container section.about .carousel .slides li img {
            display: block;
            width: 285px; }
        .container section.about .carousel .slides .slick-prev,
        .container section.about .carousel .slides .slick-next {
          font-size: 0;
          line-height: 0;
          position: absolute;
          top: 50%;
          display: none;
          cursor: pointer;
          color: transparent;
          border: 2px solid #FFFFFF;
          border-radius: 30px;
          outline: none;
          background: #fff;
          width: 60px;
          height: 60px;
          text-indent: -99999px; }
        .container section.about .carousel .slides .slick-next {
          background: #f0f;
          z-index: 1000;
          right: 10px;
          transition: background-position 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          background: url(/assets/img/common/caroucel_next.svg) no-repeat calc(100%) center;
          background-size: contain; }
          .container section.about .carousel .slides .slick-next:hover {
            background: url(/assets/img/common/caroucel_next.svg) no-repeat calc(100% + 5px) center;
            background-size: contain; }
        .container section.about .carousel .slides .slick-prev {
          background: #ff0;
          left: 10px;
          z-index: 1000;
          transition: background-position 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          background: url(/assets/img/common/caroucel_prev.svg) no-repeat calc(100%) center;
          background-size: contain; }
          .container section.about .carousel .slides .slick-prev:hover {
            background: url(/assets/img/common/caroucel_prev.svg) no-repeat calc(100% - 5px) center;
            background-size: contain; }
      .container section.about h3.head {
        text-align: left;
        margin-top: 40px; }
        .container section.about h3.head span {
          display: block;
          line-height: 1.0; }
          .container section.about h3.head span.headjp {
            margin-top: 14px; }
      .container section.about .statement {
        text-align: left;
        margin-bottom: 40px; }
        .container section.about .statement .big {
          font-size: 70px;
          line-height: 0.9;
          font-weight: 800;
          margin-top: 30px;
          margin-bottom: 30px; }
        .container section.about .statement .head {
          margin-bottom: 40px; }
        .container section.about .statement p {
          font-size: 16px;
          margin-bottom: 30px; }
      .container section.about .about-blocks {
        text-align: left;
        font-size: 0px;
        padding-bottom: 90px;
        padding-left: 20px;
        padding-right: 20px; }
        .container section.about .about-blocks .block {
          border-top: 4px solid #FFFFFF;
          vertical-align: top;
          padding-bottom: 40px;
          padding-top: 30px; }
          .container section.about .about-blocks .block.block-01 {
            color: #2668FF;
            border-color: #2668FF; }
          .container section.about .about-blocks .block.block-02 {
            color: #FAE100;
            border-color: #FAE100; }
          .container section.about .about-blocks .block.block-03 {
            color: #FF3E3E;
            border-color: #FF3E3E; }
          .container section.about .about-blocks .block.block-04 {
            color: #FFFFFF;
            border-color: #FFFFFF; }
          .container section.about .about-blocks .block .big {
            margin-bottom: 31px;
            font-size: 60px;
            font-weight: 800;
            line-height: 0.9; }
          .container section.about .about-blocks .block .head {
            margin-bottom: 40px; }
    .container section.floormap {
      background: #EEEEF2;
      color: #000; }
      .container section.floormap .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px; }
        .container section.floormap .inner .left {
          padding-top: 40px; }
          .container section.floormap .inner .left .map-block {
            padding: 40px;
            margin-bottom: 30px; }
            .container section.floormap .inner .left .map-block:last-child {
              margin-bottom: 0px; }
            .container section.floormap .inner .left .map-block.funs {
              background: #FAE100;
              color: #000; }
            .container section.floormap .inner .left .map-block.ex {
              background: #555560;
              color: #FFFFFF; }
            .container section.floormap .inner .left .map-block.shop {
              background: #2668FF;
              color: #FFFFFF; }
            .container section.floormap .inner .left .map-block .logo {
              width: 120px;
              margin-top: 5px; }
            .container section.floormap .inner .left .map-block .desc {
              margin-top: 25px; }
          .container section.floormap .inner .left .btn_funs {
            display: block;
            background: #FF3E3E;
            line-height: 36px;
            padding-top: 31px;
            padding-bottom: 31px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 30px; }
            .container section.floormap .inner .left .btn_funs i {
              width: 36px;
              height: 36px;
              transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
            .container section.floormap .inner .left .btn_funs:hover {
              opacity: 1.0; }
              .container section.floormap .inner .left .btn_funs:hover i {
                transform: translate(0px, -5px); }
        .container section.floormap .inner .right img {
          margin-top: 40px;
          width: 100%; }
        .container section.floormap .inner .right .head span {
          display: block;
          line-height: 1.0; }
          .container section.floormap .inner .right .head span.headjp {
            margin-top: 14px; }
    .container section.archive {
      background: #2668FF;
      color: #FFFFFF;
      position: relative;
      padding-bottom: 40px; }
      .container section.archive .inner {
        padding-top: 40px; }
        .container section.archive .inner div.head {
          margin-left: 20px;
          margin-right: 20px;
          padding-bottom: 30px; }
          .container section.archive .inner div.head span {
            display: block;
            line-height: 1.0; }
            .container section.archive .inner div.head span.headjp {
              margin-top: 14px; }
        .container section.archive .inner .archive-blocks {
          text-align: center; }
          .container section.archive .inner .archive-blocks .archive-list {
            font-size: 0;
            letter-spacing: -300em;
            display: inline-block; }
            .container section.archive .inner .archive-blocks .archive-list li {
              margin-bottom: 40px;
              display: inline-block;
              width: calc(100% - 40px);
              max-width: 335px;
              margin-left: 20px;
              margin-right: 20px;
              vertical-align: top;
              position: relative;
              text-align: left;
              transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
              .container section.archive .inner .archive-blocks .archive-list li.hover {
                opacity: 0.7; }
              .container section.archive .inner .archive-blocks .archive-list li a {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%; }
              .container section.archive .inner .archive-blocks .archive-list li span {
                display: block; }
                .container section.archive .inner .archive-blocks .archive-list li span.date {
                  margin-top: 10px; }
                .container section.archive .inner .archive-blocks .archive-list li span.title {
                  margin-top: 4px; }
              .container section.archive .inner .archive-blocks .archive-list li figure {
                width: calc(100vw - 40px);
                height: calc(100vw - 40px);
                max-width: 335px;
                max-height: 335px;
                background: #EEEEF2;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                display: block; }
                .container section.archive .inner .archive-blocks .archive-list li figure figcaption {
                  color: #000;
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  width: 100px;
                  height: 33px;
                  line-height: 33px;
                  background: #FAE100; }
                .container section.archive .inner .archive-blocks .archive-list li figure img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 40px); }
      .container section.archive a.btn_more {
        margin-left: 20px;
        margin-right: 20px;
        display: block;
        background: #FF3E3E;
        letter-spacing: 0.3em;
        line-height: 36px;
        padding-top: 31px;
        padding-bottom: 31px;
        text-align: center; }
        .container section.archive a.btn_more i {
          width: 36px;
          height: 36px;
          transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
        .container section.archive a.btn_more:hover {
          opacity: 1.0; }
          .container section.archive a.btn_more:hover i {
            transform: translate(10px, 0px); }
    .container section.ex-kv .inner img {
      width: 100%; }
    .container section.ex-summaly .inner {
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px; }
      .container section.ex-summaly .inner .showing {
        padding-top: 40px; }
        .container section.ex-summaly .inner .showing .head span {
          display: block;
          line-height: 1.0; }
          .container section.ex-summaly .inner .showing .head span.headjp {
            margin-top: 14px; }
        .container section.ex-summaly .inner .showing .date {
          font-size: 20px;
          font-weight: 600;
          margin-top: 20px; }
        .container section.ex-summaly .inner .showing .desc {
          margin-top: 40px; }
      .container section.ex-summaly .inner nav {
        margin-top: 40px;
        margin-bottom: 40px; }
        .container section.ex-summaly .inner nav li {
          border-top: 2px solid #000;
          position: relative;
          list-style-type: none;
          margin-right: -20px; }
          .container section.ex-summaly .inner nav li:last-child {
            border-bottom: 2px solid #000; }
          .container section.ex-summaly .inner nav li span {
            display: block;
            padding-right: 60px; }
          .container section.ex-summaly .inner nav li a {
            padding-top: 26px;
            padding-bottom: 26px;
            transition: background-position 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
            display: block;
            background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 20px) center;
            background-size: 36px 36px; }
            .container section.ex-summaly .inner nav li a:hover {
              opacity: 1.0;
              background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 10px) center;
              background-size: 36px 36px; }
    .container section.ex-outline {
      background: #2668FF;
      color: #FFFFFF;
      padding-top: 40px;
      padding-bottom: 60px; }
      .container section.ex-outline .inner {
        padding-left: 20px;
        padding-right: 20px;
        position: relative; }
        .container section.ex-outline .inner h2 {
          margin-bottom: 40px;
          line-height: 1.0; }
        .container section.ex-outline .inner .info {
          margin-bottom: 30px; }
          .container section.ex-outline .inner .info ul li {
            list-style-type: none;
            margin-bottom: 15px; }
            .container section.ex-outline .inner .info ul li span {
              display: block;
              vertical-align: top; }
              .container section.ex-outline .inner .info ul li span.head {
                width: 110px; }
              .container section.ex-outline .inner .info ul li span.head-l {
                width: 180px; }
          .container section.ex-outline .inner .info p {
            margin-bottom: 15px; }
            .container section.ex-outline .inner .info p span {
              display: inline-block;
              vertical-align: top; }
              .container section.ex-outline .inner .info p span.head {
                width: 110px; }
              .container section.ex-outline .inner .info p span.head-l {
                width: 180px; }
        .container section.ex-outline .inner .note ul {
          padding-left: 1em; }
          .container section.ex-outline .inner .note ul li {
            list-style-type: none;
            position: relative;
            margin-bottom: 20px; }
            .container section.ex-outline .inner .note ul li:before {
              content: "※";
              display: block;
              position: absolute;
              top: 0px;
              left: -1em; }
        .container section.ex-outline .inner .note p {
          margin-bottom: 20px; }
        .container section.ex-outline .inner .btn_map {
          color: #FFFFFF;
          display: block;
          background: #FF3E3E;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center; }
          .container section.ex-outline .inner .btn_map i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
          .container section.ex-outline .inner .btn_map:hover {
            opacity: 1.0; }
            .container section.ex-outline .inner .btn_map:hover i {
              transform: translate(0px, -5px); }
        .container section.ex-outline .inner .left {
          position: relative;
          box-sizing: border-box; }
        .container section.ex-outline .inner .right {
          margin-top: 40px;
          position: relative; }
    .container section.ex-about {
      background: #FAE100;
      color: #000;
      padding-bottom: 80px; }
      .container section.ex-about .inner {
        position: relative; }
        .container section.ex-about .inner h2 {
          margin-bottom: 40px;
          line-height: 1.0; }
        .container section.ex-about .inner .left {
          position: relative;
          box-sizing: border-box;
          padding-left: 20px;
          padding-right: 20px;
          margin-top: 40px; }
          .container section.ex-about .inner .left .txt_about {
            font-size: 16px; }
        .container section.ex-about .inner .right {
          padding-left: 55px;
          font-size: 0px;
          z-index: 1; }
          .container section.ex-about .inner .right figure {
            margin-top: -40px;
            display: inline-block; }
            .container section.ex-about .inner .right figure img {
              width: 100%;
              margin-bottom: 20px; }
              .container section.ex-about .inner .right figure img:last-child {
                margin-bottom: 0px; }
    .container section.ex-highlights {
      background: #EEEEF2;
      color: #000;
      padding-top: 40px;
      padding-bottom: 40px; }
      .container section.ex-highlights .inner {
        padding-left: 20px;
        padding-right: 20px; }
        .container section.ex-highlights .inner h2 {
          margin-bottom: 40px;
          line-height: 1.0; }
        .container section.ex-highlights .inner .highlights .item {
          margin-bottom: 30px; }
          .container section.ex-highlights .inner .highlights .item img {
            width: 100%; }
          .container section.ex-highlights .inner .highlights .item span {
            font-size: 16px;
            display: block;
            width: 100%;
            margin-top: 10px; }
            .container section.ex-highlights .inner .highlights .item span a {
              text-decoration: underline; }
    .container section.ex-goods {
      background: #2668FF;
      color: #FFFFFF;
      position: relative; }
      .container section.ex-goods .inner {
        padding-top: 40px; }
        .container section.ex-goods .inner div.head {
          padding-left: 20px;
          padding-right: 20px; }
          .container section.ex-goods .inner div.head span {
            display: block;
            line-height: 1.0; }
            .container section.ex-goods .inner div.head span.headjp {
              margin-top: 14px; }
        .container section.ex-goods .inner .goods-blocks {
          padding-top: 40px;
          text-align: center; }
          .container section.ex-goods .inner .goods-blocks .goods-list {
            font-size: 0;
            letter-spacing: -300em;
            display: inline-block; }
            .container section.ex-goods .inner .goods-blocks .goods-list li {
              margin-bottom: 40px;
              display: inline-block;
              width: calc(100% - 40px);
              max-width: 335px;
              margin-left: 20px;
              margin-right: 20px;
              vertical-align: top;
              position: relative;
              text-align: left;
              transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
              .container section.ex-goods .inner .goods-blocks .goods-list li.hover {
                opacity: 0.7; }
              .container section.ex-goods .inner .goods-blocks .goods-list li a {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%; }
              .container section.ex-goods .inner .goods-blocks .goods-list li p {
                display: block; }
                .container section.ex-goods .inner .goods-blocks .goods-list li p.name {
                  margin-top: 10px; }
                .container section.ex-goods .inner .goods-blocks .goods-list li p.note {
                  margin-top: 4px; }
                .container section.ex-goods .inner .goods-blocks .goods-list li p span {
                  font-style: normal;
                  color: #FF3E3E; }
              .container section.ex-goods .inner .goods-blocks .goods-list li figure {
                width: calc(100vw - 40px);
                height: calc(100vw - 40px);
                max-width: 335px;
                max-height: 335px;
                background: #EEEEF2;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                display: block; }
                .container section.ex-goods .inner .goods-blocks .goods-list li figure figcaption {
                  color: #000;
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  height: 33px;
                  line-height: 33px;
                  padding-left: 10px;
                  padding-right: 10px;
                  background: #FAE100; }
                .container section.ex-goods .inner .goods-blocks .goods-list li figure img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 40px); }
    .container section.ex-copyright {
      background: #EEEEF2;
      color: #000;
      position: relative; }
      .container section.ex-copyright .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px; }
    .container section.ex-events {
      color: #000;
      position: relative; }
      .container section.ex-events:nth-child(odd) {
        background: #EEEEF2; }
      .container section.ex-events:nth-child(even) {
        background: #E7E7EB; }
      .container section.ex-events .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px; }
        .container section.ex-events .inner h2 {
          margin-bottom: 40px; }
        .container section.ex-events .inner .item {
          margin-bottom: 90px;
          padding: 20px;
          padding-bottom: 90px;
          background: white;
          position: relative; }
          .container section.ex-events .inner .item h3 {
            margin-bottom: 40px; }
          .container section.ex-events .inner .item .link {
            position: absolute;
            bottom: -50px;
            left: 20px; }
            .container section.ex-events .inner .item .link a {
              color: #FFFFFF;
              display: block;
              background: #FF3E3E;
              line-height: 36px;
              padding-top: 31px;
              padding-bottom: 31px;
              text-align: center;
              width: calc(100vw - 40px); }
              .container section.ex-events .inner .item .link a i {
                width: 36px;
                height: 36px;
                transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
              .container section.ex-events .inner .item .link a:hover {
                opacity: 1.0; }
                .container section.ex-events .inner .item .link a:hover i {
                  transform: translate(5px, 0px); }
            .container section.ex-events .inner .item .link a[target="_blank"]:hover i {
              transform: translate(0px, -5px); }
    .container section.ex-free {
      color: #000;
      position: relative; }
      .container section.ex-free:nth-child(odd) {
        background: #EEEEF2; }
      .container section.ex-free:nth-child(even) {
        background: #E7E7EB; }
      .container section.ex-free .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px; }
        .container section.ex-free .inner h2 {
          margin-bottom: 40px; }
        .container section.ex-free .inner .lead {
          margin-bottom: 40px; }
        .container section.ex-free .inner .item {
          margin-bottom: 40px; }
          .container section.ex-free .inner .item:last-child {
            margin-bottom: 0px; }
          .container section.ex-free .inner .item.item-l .desc {
            width: 100%;
            float: none; }
          .container section.ex-free .inner .item figure {
            margin-bottom: 20px; }
            .container section.ex-free .inner .item figure img {
              width: 100%; }
          .container section.ex-free .inner .item .desc h3 {
            margin-bottom: 20px; }
          .container section.ex-free .inner .item .desc small {
            display: inline-block;
            font-weight: 600;
            font-size: 12px; }
        .container section.ex-free .inner .link {
          margin-top: 40px; }
          .container section.ex-free .inner .link a {
            margin-bottom: 40px;
            color: #FFFFFF;
            display: block;
            background: #FF3E3E;
            line-height: 36px;
            padding-top: 31px;
            padding-bottom: 31px;
            text-align: center;
            margin: 0 auto;
            width: 100%; }
            .container section.ex-free .inner .link a i {
              width: 36px;
              height: 36px;
              transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
            .container section.ex-free .inner .link a:hover {
              opacity: 1.0; }
              .container section.ex-free .inner .link a:hover i {
                transform: translate(5px, 0px); }
          .container section.ex-free .inner .link a[target="_blank"]:hover i {
            transform: translate(0px, -5px); }
    .container section.topics-list {
      position: relative;
      background: #FAE100;
      color: #000; }
      .container section.topics-list .inner {
        padding-left: 20px;
        padding-top: 40px;
        padding-bottom: 0px; }
        .container section.topics-list .inner h2 {
          margin-bottom: 80px;
          line-height: 1.0; }
        .container section.topics-list .inner .topcis-area li {
          border-top: 2px solid #000;
          position: relative;
          list-style-type: none; }
          .container section.topics-list .inner .topcis-area li span {
            display: block;
            padding-right: 60px;
            font-size: 16px; }
          .container section.topics-list .inner .topcis-area li a {
            padding-top: 26px;
            padding-bottom: 26px;
            display: block;
            transition: background-position 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
            background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 20px) center;
            background-size: 36px 36px; }
            .container section.topics-list .inner .topcis-area li a:hover {
              opacity: 1.0;
              background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 10px) center;
              background-size: 36px 36px; }
      .container section.topics-list .pagination {
        margin: 0 auto;
        text-align: center;
        font-size: 0;
        letter-spacing: -100em;
        padding-top: 0px;
        padding-bottom: 40px; }
        .container section.topics-list .pagination a.btn_next,
        .container section.topics-list .pagination a.btn_prev {
          color: #FFFFFF;
          display: block;
          background: #FF3E3E;
          letter-spacing: 0.3em;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center; }
          .container section.topics-list .pagination a.btn_next i,
          .container section.topics-list .pagination a.btn_prev i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
          .container section.topics-list .pagination a.btn_next:hover,
          .container section.topics-list .pagination a.btn_prev:hover {
            opacity: 1.0; }
            .container section.topics-list .pagination a.btn_next:hover.btn_prev i,
            .container section.topics-list .pagination a.btn_prev:hover.btn_prev i {
              transform: translate(-10px, 0px); }
            .container section.topics-list .pagination a.btn_next:hover.btn_next i,
            .container section.topics-list .pagination a.btn_prev:hover.btn_next i {
              transform: translate(10px, 0px); }
        .container section.topics-list .pagination a.btn_next {
          margin-left: 40px; }
        .container section.topics-list .pagination a.btn_prev {
          margin-right: 40px; }
        .container section.topics-list .pagination .pagination-area {
          display: inline-block;
          padding-top: 30px;
          padding-bottom: 30px; }
          .container section.topics-list .pagination .pagination-area li {
            display: inline-block;
            margin-right: 15px;
            width: 40px; }
            .container section.topics-list .pagination .pagination-area li:last-child {
              margin-right: 0px; }
            .container section.topics-list .pagination .pagination-area li.current a {
              background: #FFFFFF;
              color: #000; }
            .container section.topics-list .pagination .pagination-area li a {
              border-radius: 25px;
              display: block;
              line-height: 40px;
              width: 40px;
              height: 40px;
              color: #FFFFFF;
              background: #000; }
              .container section.topics-list .pagination .pagination-area li a span {
                display: inline-block;
                transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
              .container section.topics-list .pagination .pagination-area li a:hover {
                opacity: 1.0;
                text-decoration: none; }
                .container section.topics-list .pagination .pagination-area li a:hover span {
                  transform: translate(0px, -3px); }
    .container section.topics-detail {
      background: #FAE100;
      color: #000;
      padding-top: 40px;
      padding-bottom: 40px; }
      .container section.topics-detail .inner {
        padding-left: 20px;
        padding-right: 20px;
        position: relative; }
        .container section.topics-detail .inner .topics-info .title {
          margin-bottom: 10px; }
        .container section.topics-detail .inner .topics-body {
          margin-top: 60px; }
          .container section.topics-detail .inner .topics-body p {
            font-size: 16px;
            margin-bottom: 60px; }
            .container section.topics-detail .inner .topics-body p:last-child {
              margin-bottom: 0px; }
          .container section.topics-detail .inner .topics-body img {
            display: block;
            width: 100%;
            max-width: 840px;
            margin: 40px auto; }
    .container section.topics-related {
      background: #2668FF;
      color: #FFFFFF;
      position: relative; }
      .container section.topics-related .inner {
        padding-top: 40px; }
        .container section.topics-related .inner h2.head {
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 40px; }
          .container section.topics-related .inner h2.head span {
            display: block;
            line-height: 1.0; }
            .container section.topics-related .inner h2.head span.headjp {
              margin-top: 14px; }
        .container section.topics-related .inner .related-blocks {
          text-align: center; }
          .container section.topics-related .inner .related-blocks .related-list {
            font-size: 0;
            letter-spacing: -300em;
            display: inline-block; }
            .container section.topics-related .inner .related-blocks .related-list li {
              margin-bottom: 40px;
              display: inline-block;
              width: calc(100% - 40px);
              max-width: 335px;
              margin-left: 20px;
              margin-right: 20px;
              vertical-align: top;
              position: relative;
              text-align: left;
              transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
              .container section.topics-related .inner .related-blocks .related-list li.hover {
                opacity: 0.7; }
              .container section.topics-related .inner .related-blocks .related-list li a {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%; }
              .container section.topics-related .inner .related-blocks .related-list li span {
                display: block; }
                .container section.topics-related .inner .related-blocks .related-list li span.date {
                  margin-top: 10px; }
                .container section.topics-related .inner .related-blocks .related-list li span.title {
                  margin-top: 4px; }
              .container section.topics-related .inner .related-blocks .related-list li figure {
                width: calc(100vw - 40px);
                height: calc(100vw - 40px);
                max-width: 335px;
                max-height: 335px;
                background: #EEEEF2;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                display: block; }
                .container section.topics-related .inner .related-blocks .related-list li figure figcaption {
                  color: #000;
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  width: 100px;
                  height: 33px;
                  line-height: 33px;
                  background: #FAE100; }
                .container section.topics-related .inner .related-blocks .related-list li figure img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 40px); }
    .container section.p404 {
      color: #000;
      position: relative;
      background: #EEEEF2; }
      .container section.p404 .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px; }
        .container section.p404 .inner h1 {
          margin-bottom: 40px; }
        .container section.p404 .inner p {
          margin-bottom: 40px; }
        .container section.p404 .inner a {
          color: #FFFFFF;
          display: block;
          background: #FF3E3E;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          margin-left: -20px; }
          .container section.p404 .inner a i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
          .container section.p404 .inner a:hover {
            opacity: 1.0; }
            .container section.p404 .inner a:hover i {
              transform: translate(5px, 0px); }
    .container section.privacy {
      color: #000;
      position: relative;
      background: #EEEEF2; }
      .container section.privacy .inner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px; }
        .container section.privacy .inner h3 {
          margin-bottom: 2em;
          font-weight: 600;
          font-size: 20px; }
        .container section.privacy .inner p {
          margin-bottom: 1em; }
  footer {
    background: #000;
    color: #FFFFFF;
    position: relative;
    padding-bottom: 80px;
    padding-top: 80px; }
    footer .inner {
      padding-left: 20px;
      padding-right: 20px; }
      footer .inner .logo {
        margin-bottom: 70px;
        text-align: center; }
        footer .inner .logo img {
          width: 100%;
          max-width: 280px; }
      footer .inner .sns {
        margin-bottom: 30px;
        font-size: 0;
        letter-spacing: -300em; }
        footer .inner .sns a {
          box-sizing: border-box;
          border-radius: 30px;
          border: 2px solid #FFFFFF;
          display: inline-block;
          width: 60px;
          height: 60px;
          text-align: center;
          margin-right: 20px; }
          footer .inner .sns a i {
            width: 36px;
            height: 100%;
            background-size: contain;
            transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
          footer .inner .sns a:hover {
            opacity: 1.0; }
            footer .inner .sns a:hover i {
              transform: scale(1.2, 1.2); }
      footer .inner .address {
        margin-bottom: 30px; }
      footer .inner .schedule {
        margin-bottom: 40px; }
        footer .inner .schedule ul li {
          list-style-type: none;
          margin-bottom: 20px; }
          footer .inner .schedule ul li span {
            display: inline-block;
            vertical-align: top; }
            footer .inner .schedule ul li span.head {
              width: 110px; }
        footer .inner .schedule p {
          margin-bottom: 20px; }
          footer .inner .schedule p span {
            display: inline-block;
            vertical-align: top; }
            footer .inner .schedule p span.head {
              width: 110px; }
      footer .inner .copy {
        margin-bottom: 40px; }
      footer .inner .banners {
        font-size: 0;
        letter-spacing: -300em; }
        footer .inner .banners a {
          display: inline-block;
          margin-bottom: 20px;
          margin-right: 20px;
          width: 185px; }
          footer .inner .banners a img {
            width: 100%; }
      footer .inner .btn_up {
        position: absolute;
        bottom: 93px;
        right: 20px;
        display: block;
        width: 90px;
        height: 90px;
        border-radius: 45px;
        background: #FAE100; }
        footer .inner .btn_up img {
          transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          width: 90px; }
        footer .inner .btn_up:hover {
          opacity: 1.0; }
          footer .inner .btn_up:hover img {
            transform: translate(0px, -5px); } }
