@charset "utf-8";
@import "lib/reset.min.css";
@import "lib/slick.css";
@import "lib/slick-theme.css";
@import "_vars.sass";

@media only screen and (min-width: $breakpoint1) {

  .is-sp {
    display: none !important; }
  .is-tb {
    display: none !important; }

  html {
 }    // overflow-y: scroll;


  body {
    color: $black;
    background: $white;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "palt";
    font-family: "Poppins", "dnp-shuei-gothic-kin-std", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Arial, sans-serif;
    letter-spacing: 0.05em;
    overflow-x: hidden;
    line-height: 1.5;
    font-size: 16px;

    &.index {
      color: $white;
      background: $text-black;
      header {
        color: $white;
        background: $black; }
      #menu {
        color: $white;
        background: $black; } }
    &.en {
      footer {
        .inner {
          .schedule {
            ul {
              li {
                span {
                  &.head {
                    width: 170px; } } } } } } }
      section.title {
        .inner {
          .head {
            span {
              &.headjp {
                letter-spacing: 0em; } } } } }

      section.kv {
        .inner {
          .left {
            .showing {
              a {
                letter-spacing: 0em; } } } } }
      section.upcoming {
        .inner {
          .right {
            .info {
              a {
                letter-spacing: 0em; } } } } }

      section.access {
        .inner {
          .schedule {
            ul {
              li {
                span {
                  &.head {
                    width: 170px; } } } } } } }

      section.access {
        background: #555560;
        color: $white;
        .inner {
          .pub-transport {
            margin-top: 0px;
            position: absolute;
            right: calc(((100vw - 1280px) / 2) * -1);
            top: -60px;
            z-index: 10; }
          .note {
            margin-top: 40px;
            position: static; } } }

      section.about {
        .inner {}
        .statement {
          .head {
            font-weight: 800;
            font-size: 40px; } } }
      .about-blocks {
          .block {
            .head {
              font-weight: 800;
              margin-bottom: 40px; } } }

      section.archive {
        .inner {
          .head {
            span {
              &.headjp {
                letter-spacing: 0em; } } } }
        a.btn_more {
          letter-spacing: 0em; } } } }

  a {
    color: inherit;
    text-decoration: none;
    transition: opacity 0.3s $easeOutQuart;
    &:hover {
      opacity: .6;
      text-decoration: none; } }
  ul {
    li {
      list-style-type: none; } }

  .type-test {
    .container {
      padding: 100px; }
    p {
      margin-bottom: 30px; } }

  .h1 {
    font-weight: 600;
    font-size: 28px; }

  .h1e {
    font-size: 40px;
    font-weight: 700; }

  .h2 {
    font-weight: 700;
    font-size: 24px; }

  .h3 {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.3em; }

  .h3e {
    font-weight: 600;
    font-size: 20px; }

  .h4 {
    font-weight: 500;
    font-size: 20px; }

  .h4b {
    font-size: 20px;
    font-weight: 600; }

  .h4bb {
    font-size: 20px;
    font-weight: 700; }

  .ui {
    font-weight: 700;
    font-size: 16px; }

  .h5 {
    font-weight: 600;
    font-size: 16px; }

  .h5l {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.3em; }

  .h5m {
    font-weight: 500;
    font-size: 16px; }

  .sub {
    font-weight: 600;
    font-size: 12px; }

  .sube {
    font-size: 12px;
    font-weight: 700; }

  header {
    width: 100%;
    height: 128px;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
    position: fixed;
    top: 0px;
    left: 0px;
    background: $white;
    z-index: 1200;
    .inner {
      width: 1280px;
      max-width: 1680px;
      margin: 0 auto;
      line-height: 48px;
      position: relative;
      height: 48px;
      overflow: hiden;
      .btn_menu {
        position: absolute;
        top: 0px;
        left: -12px; }
      .btn_close {
        position: absolute;
        top: 0px;
        left: -12px;
        display: none; }
      .title {
        position: absolute;
        top: 14px;
        left: 220px;
        img {
          height: 23px; } }
      .twtter {
        position: absolute;
        top: 0px;
        right: 101px; }

      .lang {
        position: absolute;
        top: 0px;
        right: 0px;
        a.current {
          opacity: 0.5; } } } }

  #menu {
    width: 100%;
    position: fixed;
    top: -250px;
    left: 0px;
    z-index: 1100;
    padding-bottom: 72px;
    pointer-events: none;
    transition: top 0.3s $easeOutQuart;
    background: $white;
    color: $black;
    font-weight: 600;
    &.show {
      top: 128px;
      pointer-events: auto; }
    .inner {
      width: 1280px;
      margin: 0 auto;
      position: relative;
      nav {
        padding-left: 220px;
        ul {
          float: left;
          width: 290px;
          margin-right: 40px;
          &:last-child {
            margin-right: 0px; }
          li {
            font-size: 20px;
            margin-bottom: 4px;
            line-height: 36px;
            a {
              line-height: 36px;
              display: block; }
            i {
              width: 36px;
              height: 36px; }
            &:last-child {
              margin-bottom: 0px; } } } } } }

  .container {
    padding-top: 128px;

    section.title {
      padding-bottom: 60px;
      .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        .info {
          width: 840px;
          float: right;
          top: 0px;
          left: 440px;
          padding-bottom: 100px;
          h1 {
            margin-bottom: 40px; } }
        .head {
          width: 440px;
          float: left;
          .date {
            margin-top: 40px;
            padding-bottom: 100px; }
          span {
            display: block;
            // line-height: 1.0
            &.headjp {
              margin-top: 14px; } } } } }

    section.kv {
      box-sizing: border-box;
      .inner {
        // width: 1280px
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        padding-bottom: 80px;
        .left {
          width: calc(100% - 840px);
          // height: calc(100vh - 128px)
          float: left;
          position: relative;
          box-sizing: border-box;
          padding-right: 40px;
          h1 {
            margin-top: 80px;
            img {
              width: 100%; } }
          .showing {
            &:before {
              content: "";
              display: block;
              height: calc(100vh - 920px);
              min-height: 120px;
              max-height: calc(100vw - 1000px); }
            padding-right: 40px;
            margin-bottom: 40px;

            .head {
              span {
                display: block;
                line-height: 1.0;
                &.headjp {
                  margin-top: 14px; } } }

            .ex-title {
              margin-top: 60px; }

            .date {
              margin-top: 20px; }

            .desc {
              margin-top: 40px;
              margin-bottom: 60px; }

            a {
              color: $white;
              display: block;
              background: $red;
              letter-spacing: 0.3em;
              line-height: 36px;
              padding-top: 31px;
              padding-bottom: 31px;
              text-align: center;
              margin-left: calc((((100vw - 1280px) / 2)) * -1);
              width: calc((((100vw - 1280px) / 2)) + 100% + 40px);
              i {
                width: 36px;
                height: 36px;
                transition: transform 0.3s $easeOutQuart; }
              &:hover {
                opacity: 1.0;
                i {
                  transform: translate(10px, 0px); } } } } }
        .right {
          width: 840px;
          float: right;
          position: relative;
          img {
            width: calc((((100vw - 1280px) / 2))+ 100%);
            max-width: calc(((1650px - 1280px) / 2) + 100%); } } } }

    section.upcoming {
      background: $blue;
      color: $white;
      .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        padding-bottom: 80px;
        .left {
          width: 440px;
          float: left;
          position: relative;
          box-sizing: border-box;
          padding-right: 40px;
          img {
            width: calc(100% + 40px);
            margin-top: -80px;
            margin-left: -40px;
            border: 5px solid $blue;
            box-sizing: border-box;
 } }            // width: calc(((100vw - 1280px) / 2) + 100%)

        .right {
          width: 840px;
          float: right;
          position: relative;
          .head {
            margin-top: 90px;
            span {
              display: block;
              line-height: 1.0;
              &.headjp {
                margin-top: 14px; } } }

          .info {
            position: relative;
            .ex-title {
              margin-top: 40px;
              width: 400px; }

            .date {
              margin-top: 20px; }

            a {
              color: $white;
              position: absolute;
              top: 0px;
              right: 0px;
              display: block;
              background: $red;
              letter-spacing: 0.3em;
              line-height: 36px;
              padding-top: 31px;
              padding-bottom: 31px;
              text-align: center;
              width: calc(((100vw - 1280px) / 2) + 400px);
              margin-right: calc(((100vw - 1280px) / 2) * -1);
              i {
                width: 36px;
                height: 36px;
                transition: transform 0.3s $easeOutQuart; }
              &:hover {
                opacity: 1.0;
                i {
                  transform: translate(10px, 0px); } } } } } } }

    section.pr {
      background: $stone;
      color: $black;
      .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        padding-top: 80px;
        padding-bottom: 80px;
        ul {
          font-size: 0px;
          letter-spacing: -100em;
          li {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 620px;
            margin-bottom: 40px;
            transition: opacity 0.3s $easeOutQuart;
            letter-spacing: 0.0em;
            &.hover {
              opacity: 0.7; }
            &:nth-child(even) {
              margin-left: 40px; }
            figure {
              position: relative;
              figcaption {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 100px;
                height: 33px;
                line-height: 33px;
                text-align: center;
                background: $yellow; } }
            .title {
              display: block;
              width: 100%;
              background: $gray;
              box-sizing: border-box;
              padding-left: 40px;
              padding-right: 40px;
              padding-top: 32px;
              padding-bottom: 32px;
              line-height: 36px;
              color: $white;
              i {
                width: 36px;
                height: 36px; } }
            a {
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%; } } } } }

    section.topics {
      position: relative;
      .inner {
        background: $yellow;
        color: $black;
        width: calc(((100% - 1280px) / 2) + 620px);
        position: absolute;
        right: 0px;
        top: -60px;
        z-index: 1;
        .head {
          margin-top: 30px;
          margin-left: 40px;
          span {
            display: block;
            line-height: 1.0;
            &.headjp {
              margin-top: 14px; } } }

        .topcis-list {
          margin-left: 40px;
          margin-top: 40px;
          li {
            border-top: 2px solid $black;
            position: relative;
            span {
              display: block;
              width: 582px; }
            a {
              padding-top: 26px;
              padding-bottom: 26px;
              display: block;
              transition: background-position 0.3s $easeOutQuart;
              background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 40px) center;
              background-size: 36px 36px;
              &:hover {
                opacity: 1.0;
                background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 30px) center;
                background-size: 36px 36px; } } } }

        a.btn_more {
          display: block;
          background: $red;
          letter-spacing: 0.3em;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          width: 100%;
          color: $white;
          i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s $easeOutQuart; }
          &:hover {
            opacity: 1.0;
            i {
              transform: translate(10px, 0px); } } } } }

    section.access {
      background: $blue;
      color: $white;
      .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 70px;
        padding-bottom: 120px;
        position: relative;
        h3.head {
          span {
            display: block;
            line-height: 1.0;
            &.headjp {
              margin-top: 14px; } } }

        .address {
          margin-top: 40px;
          margin-bottom: 40px; }

        .btn_map {
          color: $white;
          display: block;
          background: $red;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          margin-left: calc((((100vw - 1280px) / 2)) * -1);
          width: calc((((100vw - 1280px) / 2)) + 400px);
          i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s $easeOutQuart; }
          &:hover {
            opacity: 1.0;
            i {
              transform: translate(0px, -5px); } } }

        .schedule {
          width: 620px;
          margin-top: 40px;
          ul {
            li {
              list-style-type: none;
              margin-bottom: 20px;
              span {
                display: inline-block;
                vertical-align: top;
                &.head {
                  width: 110px; } } }
            p {}
            margin-bottom: 20px;
            span {
              display: inline-block;
              vertical-align: top;
              &.head {
                width: 110px; } } } }

        .pub-transport {
          margin-top: 60px;
          margin-left: calc((((100vw - 1280px) / 2)) * -1);
          width: 620px;
          padding-left: calc((((100vw - 1280px) / 2)));
          background: $stone;
          color: $black;
          .transport {
            position: relative;
            padding-top: 40px;
            padding-bottom: 40px;
            &:first-child {
              border-bottom: 2px solid $black; }
            i {
              position: absolute;
              top: 40px;
              left: 0px; }
            .desc {
              margin-left: 110px;
              .head {
                margin-top: -5px;
                margin-bottom: 25px; } } } }
        .note {
          position: absolute;
          bottom: 120px;
          left: 660px;
          width: 620px;
          ul {
            padding-left: 1em; }
          li {
            &:before {
              content: "※";
              display: block;
              position: absolute;
              top: 0px;
              left: -1em; }
            position: relative;
            margin-top: 20px; }
          p {
            margin-top: 20px; } } } }

    section.about {
      background: $black;
      color: $white;
      .carousel {
        height: 760px;
        width: 100%;
        display: inline-block;
        margin-top: -60px;
        .slides {
          li {
            width: 1210px;
            padding-left: 35px;
            padding-right: 35px;
            img {
              display: block;
              width: 1140px; } }
          .slick-prev,
          .slick-next {
            font-size: 0;
            line-height: 0;
            position: absolute;
            top: 50%;
            display: none;
            cursor: pointer;
            color: transparent;
            border: 2px solid $white;
            border-radius: 30px;
            outline: none;
            background: #fff;
            width: 60px;
            height: 60px;
            text-indent: -99999px;
            z-index: 10; }
          .slick-next {
            background: #f0f;
            right: 50%;
            margin-right: -620px;

            transition: background-position 0.3s $easeOutQuart;
            background: url(/assets/img/common/caroucel_next.svg) no-repeat calc(100%) center;
            background-size: contain;
            &:hover {
              background: url(/assets/img/common/caroucel_next.svg) no-repeat calc(100% + 5px) center;
              background-size: contain; } }

          .slick-prev {
            background: #ff0;
            left: 50%;
            margin-left: -620px;

            transition: background-position 0.3s $easeOutQuart;
            background: url(/assets/img/common/caroucel_prev.svg) no-repeat calc(100%) center;
            background-size: contain;
            &:hover {
              background: url(/assets/img/common/caroucel_prev.svg) no-repeat calc(100% - 5px) center;
              background-size: contain; } } } }

      .inner {
        width: 1280px;
        margin: 0 auto;
        text-align: center; }
      h3.head {
        text-align: center;
        margin-top: 70px;
        span {
          display: block;
          line-height: 1.0;
          &.headjp {
            margin-top: 14px; } } }
      .statement {
        margin-bottom: 80px;
        .big {
          font-size: 110px;
          font-weight: 800;
          line-height: 1.0;
          margin-top: 80px;
          margin-bottom: 80px; }
        .head {
          margin-bottom: 40px; }
        p {
          margin-bottom: 30px; } }
      .about-blocks {
        text-align: left;
        font-size: 0px;
        padding-bottom: 90px;
        .block {
          width: 620px;
          float: left;
          border-top: 4px solid $white;
          vertical-align: top;
          padding-bottom: 40px;
          padding-top: 40px;
          &:nth-child(even) {
            margin-left: 20px;
            padding-right: calc((((100% - 1280px) / 2))); }
          &:nth-child(odd) {
            margin-right: 20px;
            padding-left: calc((((100% - 1280px) / 2))); }

          &.block-01 {
            color: $blue;
            border-color: $blue; }
          &.block-02 {
            color: $yellow;
            border-color: $yellow; }
          &.block-03 {
            color: $red;
            border-color: $red; }
          &.block-04 {
            color: $white;
            border-color: $white; }
          .big {
            margin-bottom: 31px;
            font-size: 110px;
            font-weight: 800;
            line-height: 98px; }
          .head {
            margin-bottom: 40px; } } } }

    section.floormap {
      background: $stone;
      color: $black;
      .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;

        .left {
          width: 400px;
          float: left;
          margin-top: -120px;
          .map-block {
            padding: 40px;
            margin-bottom: 30px;
            &:last-child {
              margin-bottom: 0px; }
            &.funs {
              background: $yellow;
              color: $black; }
            &.ex {
              background: $gray;
              color: $white; }
            &.shop {
              background: $blue;
              color: $white; }
            .logo {
              width: 180px;
              margin-top: 5px; }
            .desc {
              margin-top: 25px; } }

          .btn_funs {
            display: block;
            background: $red;
            line-height: 36px;
            padding-top: 31px;
            padding-bottom: 31px;
            text-align: center;
            color: $white;
            margin-bottom: 30px;
            i {
              width: 36px;
              height: 36px;
              transition: transform 0.3s $easeOutQuart; }
            &:hover {
              opacity: 1.0;
              i {
                transform: translate(0px, -5px); } } } }
        .right {
          width: 840px;
          float: right;
          img {
            margin-top: 40px;
            width: 100%; }

          .head {
            span {
              display: block;
              line-height: 1.0;
              &.headjp {
                margin-top: 14px; } } } } } }

    section.archive {
      background: $blue;
      color: $white;
      position: relative;
      .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;
        .head {
          span {
            display: block;
            line-height: 1.0;
            &.headjp {
              margin-top: 14px; } } }
        .archive-blocks {
          width: 1280px;
          margin-top: 50px;
          .archive-list {
            font-size: 0;
            letter-spacing: -300em;
            li {
              width: 400px;
              margin-right: 40px;
              margin-bottom: 40px;
              display: inline-block;
              vertical-align: top;
              position: relative;
              transition: opacity 0.3s $easeOutQuart;
              letter-spacing: 0.5em;
              &.hover {
                opacity: 0.7; }
              &:nth-child(3n) {
                margin-right: 0px; }
              a {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%; }
              span {
                display: block;
                &.date {
                  margin-top: 10px; }
                &.title {
                  margin-top: 4px; } }
              figure {
                width: 400px;
                height: 400px;
                background: $stone;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                display: block;
                figcaption {
                  color: $black;
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  width: 100px;
                  height: 33px;
                  line-height: 33px;
                  // text-align: center
                  background: $yellow; }
                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 40px); } } } } } }

      a.btn_more {
        display: block;
        background: $red;
        letter-spacing: 0.3em;
        line-height: 36px;
        padding-top: 31px;
        padding-bottom: 31px;
        text-align: center;
        color: $white;
        width: calc(((100% - 1280px) / 2) + 400px);
        // margin-left: calc(((100% - 1280px) / 2) + 880px)
        position: absolute;
        bottom: -40px;
        right: 0px;
        z-index: 1;
        i {
          width: 36px;
          height: 36px;
          transition: transform 0.3s $easeOutQuart; }
        &:hover {
          opacity: 1.0;
          i {
            transform: translate(10px, 0px); } } } }

    section.ex-kv {
      background: $stone;
      .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        text-align: center;
        img {
          margin-top: -80px;
          margin-bottom: -80px;
          display: inline-block;
          width: 840px; } } }

    section.ex-outline {
      background: $blue;
      color: $white;
      padding-top: 160px;
      padding-bottom: 80px;
      .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        h2 {
          margin-bottom: 40px;
          line-height: 1.0; }
        .info {
          margin-bottom: 40px;
          ul {
            li {
              margin-bottom: 15px;
              span {
                display: inline-block;
                vertical-align: top;
                &.head {
                  width: 220px; } } } }
          p {
            margin-bottom: 15px;
            span {
              display: inline-block;
              vertical-align: top;
              &.head {
                width: 220px; } } } }
        .note {
          ul {
            padding-left: 1em;
            li {
              &:before {
                content: "※";
                display: block;
                position: absolute;
                top: 0px;
                left: -1em; }
              position: relative;
              margin-bottom: 20px; } }
          p {
            margin-bottom: 20px; } }
        .btn_map {
          display: block;
          background: $red;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          margin-left: calc((((100vw - 1280px) / 2)) * -1);
          width: calc((((100vw - 1280px) / 2)) + 510px);
          i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s $easeOutQuart; }
          &:hover {
            opacity: 1.0;
            i {
              transform: translate(0px, -5px); } } }
        .left {
          width: 730px;
          float: left;
          position: relative;
          box-sizing: border-box; }
        .right {
          width: 510px;
          float: right;
          position: relative; } } }

    section.ex-about {
      background: $yellow;
      color: $black;
      padding-top: 80px;
      padding-bottom: 80px;
      .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        h2 {
          margin-bottom: 40px;
          line-height: 1.0; }
        .left {
          width: 840px;
          float: left;
          position: relative;
          box-sizing: border-box; }
        .right {
          width: 400px;
          position: absolute;
          top: -160px;
          right: 0px;
          font-size: 0px;
          z-index: 1;
          figure {
            width: 100%;
            img {
              width: 100%;
              margin-bottom: 40px;
              &:last-child {
                margin-bottom: 0px; } } } } } }

    section.ex-highlights {
      background: $stone;
      color: $black;
      padding-top: 80px;
      padding-bottom: 200px;
      .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        h2 {
          margin-bottom: 80px;
          line-height: 1.0; }
        .highlights {
          .item {
            width: 510px;
            margin-bottom: 60px;
            display: inline-block;
            vertical-align: top;
            &:nth-child(2n) {
              margin-left: 40px;
              transform: translate(0px, 130px); }
            &:last-child {
              margin-bottom: 0px; }
            img {
              width: 100%;
              margin-bottom: 30px; }
            span {
              display: block;
              width: 100%;
              margin-top: 10px;
              a {
                text-decoration: underline; } } } } } }

    section.ex-goods {
      background: $blue;
      color: $white;
      position: relative;
      .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;
        .head {
          span {
            display: block;
            line-height: 1.0;
            &.headjp {
              margin-top: 14px; } } }
        .goods-blocks {
          width: 1280px;
          margin-top: 50px;
          .goods-list {
            font-size: 0;
            letter-spacing: -300em;
            li {
              letter-spacing: 0.05em;
              width: 400px;
              margin-right: 40px;
              margin-bottom: 40px;
              display: inline-block;
              vertical-align: top;
              position: relative;
              transition: opacity 0.3s $easeOutQuart;
              &.hover {
                opacity: 0.7; }
              &:nth-child(3n) {
                margin-right: 0px; }
              a {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%; }
              p {
                &.name {
                  margin-top: 10px; }
                &.note {
                  margin-top: 4px; }
                span {
                  font-style: normal;
                  color: $red; } }
              figure {
                width: 400px;
                height: 400px;
                background: $stone;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                figcaption {
                  color: $black;
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  // width: 110px
                  height: 33px;
                  line-height: 33px;
                  padding-left: 10px;
                  padding-right: 10px;
                  // text-align: center
                  background: $yellow; }
                img {
                  // vertical-align: middle
                  // display: table-cell
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 40px); } } } } } } }

    section.ex-copyright {
      background: $stone;
      color: $black;
      position: relative;
      .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px; } }

    section.ex-events {
      color: $black;
      position: relative;
      &:nth-child(odd) {
        background: $stone; }
      &:nth-child(even) {
        background: #E7E7EB; }
      .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;
        h2 {
          margin-bottom: 80px; }
        .item {
          margin-bottom: 130px;
          padding: 80px;
          background: white;
          position: relative;
          h3 {
            margin-bottom: 40px; }
          .link {
            position: absolute;
            bottom: -50px;
            right: 0px;
            margin-right: calc( ((100vw - 1280px) * -0.5 ) );
            a {
              color: $white;
              display: block;
              background: $red;
              line-height: 36px;
              padding-top: 31px;
              padding-bottom: 31px;
              text-align: center;
              width: 50vw;
              i {
                width: 36px;
                height: 36px;
                transition: transform 0.3s $easeOutQuart; }
              &:hover {
                opacity: 1.0;
                i {
                  transform: translate(5px, 0px); } } }
            a[target="_blank"] {
              &:hover {
                i {
                  transform: translate(0px, -5px); } } } } } } }

    section.ex-free {
      color: $black;
      position: relative;
      &:nth-child(odd) {
        background: $stone; }
      &:nth-child(even) {
        background: #E7E7EB; }
      .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;
        h2 {
          margin-bottom: 80px; }
        .lead {
          margin-bottom: 80px; }
        .item {
          margin-bottom: 80px;
          &:last-child {
            margin-bottom: 0px; }
          &.item-l {
            .desc {
              width: 100%;
              float: none; } }
          figure {
            width: 440px;
            float: left;
            img {
              width: 400px; } }
          .desc {
            width: 840px;
            float: left;
            h3 {
              margin-bottom: 40px; }
            small {
              display: inline-block;
              font-weight: 600;
              font-size: 12px; } } }
        .link {
          margin-top: 80px;
          a {
            margin-bottom: 40px;
            color: $white;
            display: block;
            background: $red;
            line-height: 36px;
            padding-top: 31px;
            padding-bottom: 31px;
            text-align: center;
            margin: 0 auto;
            width: 700px;
            i {
              width: 36px;
              height: 36px;
              transition: transform 0.3s $easeOutQuart; }
            &:hover {
              opacity: 1.0;
              i {
                transform: translate(5px, 0px); } } }
          a[target="_blank"] {
            &:hover {
              i {
                transform: translate(0px, -5px); } } } } } }

    section.topics-list {
      background: $yellow;
      color: $black;
      padding-top: 80px;
      padding-bottom: 60px;
      .inner {
        width: calc((((100% - 1280px) / 2)) + 1280px);
        margin-left: calc((((100% - 1280px) / 2)));
        position: relative;
        h2 {
          margin-bottom: 80px;
          line-height: 1.0; }
        .topcis-area {
          li {
            border-top: 2px solid $black;
            position: relative;
            span {
              display: block;
              width: 1210px; }
            a {
              display: block;
              width: 1280px;
              padding-top: 26px;
              padding-bottom: 26px;
              transition: background-position 0.3s $easeOutQuart;
              background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100% - 10px) center;
              background-size: 36px 36px;
              &:hover {
                opacity: 1.0;
                background: url(/assets/img/common/icon_arrow_b.svg) no-repeat calc(100%) center;
                background-size: 36px 36px; } } } } }

      .pagination {
        width: 1280px;
        margin: 0 auto;
        text-align: center;
        font-size: 0;
        letter-spacing: -100em;
        padding-top: 80px;
        padding-bottom: 80px;
        .pagination-area {
          display: inline-block;
          li {
            display: inline-block;
            margin-right: 20px;
            width: 50px;
            &:last-child {
              margin-right: 0px; }
            &.current {
              a {
                background: $white;
                color: $black; } }
            a {
              border-radius: 25px;
              display: block;
              line-height: 50px;
              width: 50px;
              height: 50px;
              color: $white;
              background: $black;
              span {
                display: inline-block;
                transition: transform 0.3s $easeOutQuart; }
              &:hover {
                opacity: 1.0;
                text-decoration: none;
                span {
                  transform: translate(0px, -5px); } } } } } } }

    section.topics-detail {
      background: $yellow;
      color: $black;
      padding-top: 80px;
      padding-bottom: 80px;
      .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative;
        .topics-info {
          .title {
            margin-bottom: 10px; } }
        .topics-body {
          margin-top: 60px;
          p {
            margin-bottom: 60px;
            &:last-child {
              margin-bottom: 0px; } }
          img {
            display: block;
            max-width: 840px;
            margin: 40px auto; } } } }


    section.topics-related {
      background: $blue;
      color: $white;
      position: relative;
      .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;
        .head {
          span {
            display: block;
            line-height: 1.0;
            &.headjp {
              margin-top: 14px; } } }
        .related-blocks {
          width: 1320px;
          margin-left: -20px;
          margin-right: -20px;
          margin-top: 50px;
          .related-list {
            font-size: 0;
            letter-spacing: -300em;
            li {
              letter-spacing: -0.5em;
              width: 400px;
              margin-left: 20px;
              margin-right: 20px;
              margin-bottom: 40px;
              display: inline-block;
              vertical-align: top;
              position: relative;
              transition: opacity 0.3s $easeOutQuart;
              &.hover {
                opacity: 0.7; }
              a {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%; }
              span {
                display: block;
                i {
                  width: 30px;
                  height: 30px;
                  vertical-align: -8px; }
                &.title {
                  margin-top: 10px; } }
              figure {
                width: 400px;
                height: 400px;
                background: $stone;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                figcaption {
                  color: $black;
                  position: absolute;
                  top: 0px;
                  right: 0px;
                  width: 100px;
                  height: 33px;
                  line-height: 33px;
                  // text-align: center
                  background: $yellow; }
                img {
                  // vertical-align: middle
                  // display: table-cell
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 40px); } } } } } } }

    section.next-prev {
      position: relative;
      a {
        color: $white;
        display: block;
        background: $red;
        line-height: 36px;
        padding-top: 31px;
        padding-bottom: 31px;
        text-align: center;
        width: calc((((100% - 1280px) / 2)) + 620px);
        position: absolute;
        z-index: 1;
        &.btn_prev {
          top: -60px;
          left: 0px; }
        &.btn_next {
          top: -60px;
          right: 0px; }
        i {
          width: 36px;
          height: 36px;
          transition: transform 0.3s $easeOutQuart; }
        &:hover {
          opacity: 1.0;
          &.btn_prev {
            i {
              transform: translate(-10px, 0px); } }
          &.btn_next {
            i {
              transform: translate(10px, 0px); } } } } }


    section.privacy {
      color: $black;
      position: relative;
      background: $stone;
      .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;
        h3 {
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 60px; }
        p {
          margin-bottom: 1em; } } }

    section.p404 {
      color: $black;
      position: relative;
      background: $stone;
      .inner {
        width: 1280px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;
        h1 {
          margin-bottom: 60px; }
        p {
          margin-bottom: 60px; }
        a {
          color: $white;
          display: block;
          background: $red;
          line-height: 36px;
          padding-top: 31px;
          padding-bottom: 31px;
          text-align: center;
          // width: 700px
          margin-left: calc((((100vw - 1280px) / 2)) * -1);
          width: calc((((100vw - 1280px) / 2)) + 400px);
          i {
            width: 36px;
            height: 36px;
            transition: transform 0.3s $easeOutQuart; }
          &:hover {
            opacity: 1.0;
            i {
              transform: translate(5px, 0px); } } } } }

    footer {
      background: $black;
      color: $white;
      position: relative;
      padding-bottom: 120px;
      padding-top: 80px;
      .inner {
        width: 1280px;
        margin: 0 auto;
        position: relative;

        .logo {
          margin-bottom: 60px; }

        .sns {
          margin-bottom: 30px;
          font-size: 0;
          letter-spacing: -300em;
          a {
            box-sizing: border-box;
            border-radius: 30px;
            border: 2px solid $white;
            display: inline-block;
            width: 60px;
            height: 60px;
            text-align: center;
            margin-right: 20px;
            i {
              width: 36px;
              height: 100%;
              background-size: contain;
              transition: transform 0.3s $easeOutQuart; }
            &:hover {
              opacity: 1.0;
              i {
                transform: scale(1.2, 1.2); } } } }

        .address {
          margin-bottom: 20px; }

        .schedule {
          margin-bottom: 40px;
          ul {
            li {
              list-style-type: none;
              margin-bottom: 20px;
              span {
                display: inline-block;
                vertical-align: top;
                &.head {
                  width: 110px; } } } }
          p {
            margin-bottom: 20px;
            line-height: 1.0;
            span {
              display: inline-block;
              vertical-align: top;
              &.head {
                width: 110px; } } } }

        .banners {
          font-size: 0;
          letter-spacing: -300em;
          position: absolute;
          bottom: 0px;
          right: 130px;
          a {
            display: inline-block;
            margin-left: 20px;
            width: 185px;
            img {
              width: 100%; } } }

        .btn_up {
          position: absolute;
          bottom: -20px;
          right: 0px;
          display: block;
          width: 90px;
          height: 90px;
          border-radius: 45px;
          background: $yellow;
          img {
            transition: transform 0.3s $easeOutQuart;
            width: 90px; }
          &:hover {
            opacity: 1.0;
            img {
              transform: translate(0px, -5px); } } } } } } }

