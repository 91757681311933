@charset "utf-8";

//color
$black: #000;
$text-black: #1A1A1A;
$white: #FFFFFF;
$stone: #EEEEF2;
$gray: #555560;
$red: #FF3E3E;
$blue: #2668FF;
$yellow: #FAE100;

//breakpoint
$breakpoint1: 1280px;

//breakpoint
$breakpoint2: 768px;

//easing
$easeInSine:      cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine:     cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine:   cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInQuad:      cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad:     cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad:   cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInCubic:     cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic:    cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic:  cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInQuart:     cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart:    cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart:  cubic-bezier(0.77, 0, 0.175, 1);
$easeInQuint:     cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint:    cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint:  cubic-bezier(0.86, 0, 0.07, 1);
$easeInExpo:      cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo:     cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo:   cubic-bezier(1, 0, 0, 1);
$easeInCirc:      cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc:     cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc:   cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInBack:      cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack:     cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack:   cubic-bezier(0.68, -0.55, 0.265, 1.55);

@mixin mediaquery($device) {
  @if $device == pc {
    @media only screen and (min-width: $breakpoint+1) {
      @content; } }
  @else if $device == sp {
    @media only screen and (max-width: $breakpoint) {
      @content; } } }

.clearfix {
  zoom: 1;
  &:after {
    clear: both;
    display: block;
    content: ""; } }

@mixin setFont($font-size: $font-size, $line-height: $line-height, $margin-bottom: 0, $margin-top: 0) {
  font-size: $font-size;
  line-height: $line-height;
  margin-bottom: floor($margin-bottom - ($font-size * $line-height - $font-size) / 2);
  margin-top: floor($margin-top - ($font-size * $line-height - $font-size) / 2); }

@mixin setMargin($font-size: $font-size, $line-height: $line-height, $margin-bottom: 0, $margin-top: 0) {
  margin-bottom: floor($margin-bottom - ($font-size * $line-height - $font-size) / 2);
  margin-top: floor($margin-top - ($font-size * $line-height - $font-size) / 2); }

@mixin setImg($width: auto, $height: auto) {
  width: $width;
  height: $height;

  img {
    width: 100%; } }

.bold {
  font-weight: 700; }
.red {
  color: $red; }

@mixin redbtn() {
  margin-left: 20px;
  margin-right: 20px;
  display: block;
  background: $red;
  letter-spacing: 0.3em;
  line-height: 36px;
  padding-top: 31px;
  padding-bottom: 31px;
  text-align: center;
  i {
    width: 36px;
    height: 36px;
    transition: transform 0.3s $easeOutQuart; }
  &:hover {
    opacity: 1.0;
    i {
      transform: translate(10px, 0px); } } }

@mixin redenbtn() {
  margin-left: 20px;
  margin-right: 20px;
  display: block;
  background: $red;
  line-height: 36px;
  padding-top: 31px;
  padding-bottom: 31px;
  text-align: center;
  i {
    width: 36px;
    height: 36px;
    transition: transform 0.3s $easeOutQuart; }
  &:hover {
    opacity: 1.0;
    i {
      transform: translate(10px, 0px); } } }

i {
  display: inline-block;
  font-size: 0;
  width: 48px;
  height: 48px;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &.menu {
    background-image: url(/assets/img/common/icon_menu.svg); }
  &.menu_b {
    background-image: url(/assets/img/common/icon_menu_b.svg); }
  &.close {
    background-image: url(/assets/img/common/icon_close.svg); }
  &.close_b {
    background-image: url(/assets/img/common/icon_close_b.svg); }
  &.tw {
    background-image: url(/assets/img/common/icon_tw.svg); }
  &.tw_b {
    background-image: url(/assets/img/common/icon_tw_b.svg); }
  &.ig {
    background-image: url(/assets/img/common/icon_ig.svg); }
  &.blank {
    background-image: url(/assets/img/common/icon_blank.svg); }
  &.blank_b {
    background-image: url(/assets/img/common/icon_blank_b.svg); }
  &.arrow_w {
    background-image: url(/assets/img/common/icon_arrow_next_w.svg); }
  &.arrow_prev_w {
    background-image: url(/assets/img/common/icon_arrow_prev_w.svg); }
  &.train {
    width: 40px;
    height: 46px;
    background-image: url(/assets/img/common/icon_train.svg); }
  &.bus {
    width: 40px;
    height: 46px;
    background-image: url(/assets/img/common/icon_bus.svg); } }


@keyframes img-move {
  from {
    background-position: left top; }
  to {
    background-position: left bottom ; } }

@keyframes vertical {
  0% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0); } }

@keyframes blink {
  0% {
    opacity: 0.5; }
  30% {
    opacity: 1.0; } }

@keyframes spin1 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes bloom {
  0% {
    transform: scale(1.0); }
  100% {
    transform: scale(0.9); } }

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB; }

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB; }

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB; }

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB; }

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }
